/* eslint-disable */
/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EditText, EditTextarea } from 'react-edit-text';
import moment from 'moment';
import 'react-edit-text/dist/index.css';
import './styles.css';
import { useParams } from 'react-router-dom';
import { MdClose, MdMoreHoriz, MdOutlineEdit } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi'
import { useHistory } from 'react-router';
import { Button, Dropdown } from 'react-bootstrap';
import musicIcon from '../assets/musicIcon.svg';
import pauseIcon from '../assets/pauseIcon.svg';
import playIcon from '../assets/playIcon.svg';
import videoPlayIcon from '../../../../../../img/video_play.svg';
import { Howl, Howler } from 'howler';
import AssetModelPreview from './AssetModelPreview';
import {
    deleteAssetDispatch, deleteAssetFileDispatch,
    getAssetsDispatch, postAddMediaFileDispatch,
    postCreateAssetDispatch,
    putUpdateAssetFile, putUpdateAssetFilePlatform,
    updateAssetDispatch,
} from '../../../../../../actions/assets';
import byteSize from 'byte-size';
import axios from 'axios';
import { postCreateUploadDispatch } from '../../../../../../actions/uploads';
import ClipLoader from 'react-spinners/ClipLoader';
import Modal from 'react-bootstrap/Modal';
import ModalDetails from '../ModalDetails';
import useFocus from "../../../../../../util/useFocus";
import {HiChevronDown} from "react-icons/hi";

function setFileType(originalFile, newType) {
  return new File([originalFile], originalFile.name, {
    type: newType,
    lastModified: originalFile.lastModified,
  });
}

const fileNameParser = (url) => {
  const split = url.split('/');
  return split[split.length - 1];
};

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line
    <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

// eslint-disable-next-line react/display-name
const CustomToggleFiles = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line
    <a
    href=""
    style={{ color: '#0072EF' }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const parsePlatformName = (platform) => {
  switch (platform) {
    case 'all':
        return 'all';
    case 'android':
      return 'Android';
    case 'ios':
      return 'iOS';
    default:
      return ''
  }
}

const renderFilePreview = (asset, soundPlaying, playSound, stopSound) => {
  if (asset.file && asset.file.mediaType.includes('gltf')) {
    return <AssetModelPreview asset={asset} assetUrl={asset.file.url} />;
  } else if (
    asset.androidFile &&
    asset.androidFile.mediaType.includes('gltf')
  ) {
    return <AssetModelPreview asset={asset} assetUrl={asset.androidFile.url} />;
  } else if (
      asset.iosFile &&
      asset.iosFile.mediaType.includes('gltf')
  ) {
    return <AssetModelPreview asset={asset} assetUrl={asset.iosFile.url} />;
  } else if (asset.file && asset.file.mediaType.includes('audio')) {
    return (
      <div className="asset-details-audio-preview">
        <img src={musicIcon} alt="music-icon" />
        <button
          onClick={() => {
            if (soundPlaying) {
              stopSound();
            } else {
              playSound(asset.file.url);
            }
          }}
          className="asset-details-audio-preview-button"
        >
          {soundPlaying ? (
            <img src={pauseIcon} alt="play-icon" />
          ) : (
            <img src={playIcon} alt="pause-icon" />
          )}
        </button>
      </div>
    );
  } else if (asset.file && asset.file.mediaType.includes('image')) {
    return (
      <div className="asset-details-preview-image">
        <img
          style={{ maxHeight: '320px' }}
          src={asset.file.url}
          alt={asset.file.url}
        />
      </div>
    );
  } else {
    return null;
  }
};

const AssetDetailsView = (props) => {
  const { slug, objectId } = useParams();
  const [openDeleteContext, setOpenDeleteContext] = useState(false);
  const [openAddOrReplaceContext, setAddOrReplaceContext] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [deletingFileId, setDeletingFileId] = useState(null);
  const [uploadingSpinner, setUploadingSpinner] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedMediaFile, setSelectedMediaFile] = useState(null);
  const [selectedFilePlatform, setSelectedFilePlatform] = useState('all');
  const history = useHistory();
  const fileInputRef = useRef(null);
  const mediaFileInput = useRef(null);

  const activeAsset = props.assets.data.find((ass) => ass.id === objectId);
  const [assetName, setAssetName] = useState('');
  const [assetContent, setAssetContent] = useState('');
  const [editingAssetName, setEditingAssetName] = useState(false);
  const [nameEditRef, setNameEditFocus] = useFocus()
  useEffect(() => {
    if (activeAsset.title) {
      setAssetName(activeAsset.title);
    } else {
      setAssetName('');
    }
  }, [activeAsset]);

  useEffect(() => {
    if (activeAsset.description && activeAsset.type === 'label') {
      setAssetContent(activeAsset.description);
    } else {
      setAssetContent('');
    }
  }, [activeAsset]);

  const [soundPlaying, setSoundPlaying] = useState(false);

  const playSound = (url) => {
    const sound = new Howl({
      src: url,
      html5: true,
      loop: false,
      onend: function () {
        setSoundPlaying(false);
      },
    });
    sound.play();
    setSoundPlaying(true);
  };

  const stopSound = () => {
    setSoundPlaying(false);
    Howler.unload();
  };

  useEffect(() => {
    stopSound();
  }, [objectId]);

  useEffect(() => {
    return () => {
      stopSound();
    };
  }, []);

  const handleFileInput = (event) => {
    const fileInput = event.target.files[0];
    setSelectedFile(fileInput);
  };

  const handleMediaFileInput = (event) => {
    const fileInput = event.target.files[0];
    setSelectedMediaFile(fileInput);
  };

  const addOrReplaceFile = () => {
    console.log('file processing');
    setUploadingSpinner(true);
    let uploadableFile = selectedFile
    let type = uploadableFile.name.split('.').slice(-1)[0];
    if (uploadableFile.type !== '') {
      type = uploadableFile.type;
    }
    if (uploadableFile.name.split('.').slice(-1)[0] === 'glb') {
      type = 'model/gltf-binary';
      const setTypeInFile = setFileType(uploadableFile, 'model/gltf-binary');
      uploadableFile = setTypeInFile;
    }
    if (uploadableFile.name.split('.').slice(-1)[0] === 'gltf') {
      type = 'model/gltf+json';
    }
    if (uploadableFile.name.split('.').slice(-1)[0] === 'ab') {
      type = 'application/octet-stream';
    }
    if (uploadableFile.name.split('.').slice(-1)[0] === 'fbx') {
      type = 'model/fbx';
    }

    const replace = true;
    props
      .postCreateUploadDispatch({
        contentType: type,
        filename: uploadableFile.name,
      })
      .then((res) => {
        const file = uploadableFile;
        const data = new FormData();
        data.append('file', file);

        axios
          .put(res.payload.signedUrl, uploadableFile, {
            headers: {
              'content-type': type,
            },
          })
          .then(function (cloudRes) {
            console.log(cloudRes);
            if (replace) {
              props
                .putUpdateAssetFile(
                  {
                    platform: selectedFilePlatform,
                    uploadId: res.payload.id,
                  },
                  activeAsset.id
                )
                .then((asset) => {
                  props.getAssetsDispatch().then((all) => {
                    setUploadingSpinner(false);
                    history.push(`/dashboard/assets/${asset.payload.id}`);
                    setSelectedFile(null);
                    fileInputRef.current.value = null;
                  });
                })
                .catch((assetErr) => {
                  setUploadingSpinner(false);
                  alert('upload failed');
                });
            }
          })
          .catch(function (err) {
            console.log(err);
            console.log(JSON.stringify(err));
            setSelectedFile(null);
            fileInputRef.current.value = null;
            setUploadingSpinner(false);
            alert('upload failed');
          });
      })
      .catch((err) => {
        setSelectedFile(null);
        fileInputRef.current.value = null;
        alert('upload error');
      });
  };

  const addMediaFile = () => {
    console.log('file processing');
    setUploadingSpinner(true);
    let uploadableFile = selectedMediaFile;
    let type = uploadableFile.name.split('.').slice(-1)[0];
    if (uploadableFile.type !== '') {
      type = uploadableFile.type;
    }

    const replace = true;
    props
        .postCreateUploadDispatch({
          contentType: type,
          filename: uploadableFile.name,
        })
        .then((res) => {
          const file = uploadableFile;
          const data = new FormData();
          data.append('file', file);

          axios
              .put(res.payload.signedUrl, uploadableFile, {
                headers: {
                  'content-type': type,
                },
              })
              .then(function (cloudRes) {
                console.log(cloudRes);
                if (replace) {
                  props
                      .postAddMediaFileDispatch(
                          activeAsset.id, res.payload.id
                      )
                      .then((asset) => {
                        props.getAssetsDispatch().then((all) => {
                          setUploadingSpinner(false);
                          history.push(`/dashboard/assets/${asset.payload.id}`);
                          setSelectedMediaFile(null);
                          mediaFileInput.current.value = null;
                        });
                      })
                      .catch((assetErr) => {
                        setUploadingSpinner(false);
                        alert('upload failed');
                      });
                }
              })
              .catch(function (err) {
                console.log(err);
                console.log(JSON.stringify(err));
                setUploadingSpinner(false);
                setSelectedMediaFile(null);
                mediaFileInput.current.value = null;
                alert('upload failed');
              });
        })
        .catch((err) => {
          setSelectedMediaFile(null);
          mediaFileInput.current.value = null;
          alert('upload error');
        });
  };

  useEffect(() => {
    if (selectedFile) {
      addOrReplaceFile();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedMediaFile) {
      console.log(selectedMediaFile);
      addMediaFile();
    }
  }, [selectedMediaFile]);

  const getPossibleNewPlatforms = (currentPlatform, fileId) => {

    const unusedPlatforms = [];

    if (activeAsset.file) {
    } else {
      if (currentPlatform !== 'all') {
        unusedPlatforms.push('all');
      }
    }
    if (activeAsset.iosFile) {
    } else {
      if (currentPlatform !== 'ios') {
        unusedPlatforms.push('ios')
      }
    }
    if (activeAsset.androidFile) {
    } else {
      if (currentPlatform !== 'android') {
        unusedPlatforms.push('android')
      }
    }

    return unusedPlatforms.map(platform => {

      return(
          <Dropdown.Item
              key="all"
              eventKey="all"
              onClick={() => {
                props.putUpdateAssetFilePlatform(activeAsset.id, fileId, platform).then(res => [
                    props.getAssetsDispatch()
                ])
              }}
          >
            {parsePlatformName(platform)}
          </Dropdown.Item>
      )
    })
  };

  return (
    <div className="asset-details-container">
      <div className="asset-details-top">
        <Dropdown
          onToggle={(isOpen) => {
            setOpenDeleteContext(isOpen);
          }}
        >
          <Dropdown.Toggle as={CustomToggle}>
            <MdMoreHoriz style={{ height: '24px', width: '24px', color: '#686A6F' }} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              key="delete"
              eventKey="delete"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete asset
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <button
          className="asset-details-top-button"
          onClick={() => {
            history.push(`/dashboard/${slug}`);
          }}
        >
          <MdClose style={{ height: '24px', width: '24px', color: '#686A6F' }} />
        </button>
      </div>

      {renderFilePreview(activeAsset, soundPlaying, playSound, stopSound)}

      <div className="asset-details-prompt" style={{ marginTop: '24px' }}>
        {activeAsset.type === 'label'
          ? 'Label name (displayed in AR)'
          : 'Asset name'}
      </div>
      <div className="asset-details-name" style={{ marginLeft: '-4px' }}>
        <EditText
            id="editAssetName"
            ref={nameEditRef}
            style={{ fontWeight: 600 }}
          value={assetName}
          onChange={(event) => setAssetName(event)}
          placeholder={assetName === '' ? 'Insert name' : ''}
          onSave={(event) => {
            setEditingAssetName(false);
            props
              .updateAssetDispatch(activeAsset.id, {
                title: assetName,
                description: activeAsset.description,
              })
              .then((res) => {
                props.getAssetsDispatch();
              });
          }}
          onEditMode={(event) => setEditingAssetName(!event)}
        />
        <button className="asset-details-top-button" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                onClick={() => {
                  document.getElementById("editAssetName").click();
                }}
        >
          <MdOutlineEdit style={{ color: "#686A6F", width: '32px', height: '32px' }} />
        </button>
      </div>
      <div className="asset-details-meta">
        <div className="asset-details-meta-item">
          <div className="asset-details-prompt">ID</div>
          <div className="asset-details-meta-text">{activeAsset.id}</div>
        </div>
        <div className="asset-details-meta-item">
          <div className="asset-details-prompt">Modified</div>
          <div className="asset-details-meta-date">{`${moment(
            activeAsset.updatedAt
          ).format('DD MMMM YYYY HH:mm')}`}</div>
        </div>
        {activeAsset.type === 'label' ? (
          <div style={{ width: '100%' }}>
            <div className="asset-details-prompt" style={{ marginTop: '24px' }}>
              {activeAsset.type === 'label' ? 'Label content' : 'Asset name'}
            </div>
            <div className="asset-details-name" style={{ marginLeft: '-4px'}}>
              <EditTextarea
                  rows={8}
                  className="label-content"
                style={{ width: '100%', maxHeight: '800px' }}
                value={assetContent}
                onChange={(event) => setAssetContent(event)}
                placeholder={assetContent === '' ? 'Click to add content' : ''}
                onSave={(event) => {
                  setEditingAssetName(false);
                  props
                    .updateAssetDispatch(activeAsset.id, {
                      description: assetContent,
                      title: activeAsset.title,
                    })
                    .then((res) => {
                      props.getAssetsDispatch();
                    });
                }}
                onEditMode={(event) => setEditingAssetName(!event)}
              />
            </div>
          </div>
        ) : null}
        {activeAsset.type !== 'label'
            ?
        <div
          className="asset-details-meta-item"
          style={activeAsset.type === 'label' ? { marginTop: '32px' } : {}}
        >
          <div className="asset-details-prompt">Files</div>
          <div className="asset-details-meta-files">
            {activeAsset.file ? (
              <div className="asset-details-meta-files-item">
                <div className="asset-details-meta-files-item-props">
                  <div
                    className="asset-details-meta-text"
                    style={{ maxWidth: '160px' }}
                  >
                    <a
                      href={activeAsset.file.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {fileNameParser(activeAsset.file.url)}
                    </a>
                  </div>

                  <Dropdown
                      onToggle={(isOpen) => {
                        setOpenDeleteContext(isOpen);
                      }}
                  >
                    <Dropdown.Toggle as={CustomToggleFiles}>
                      <button className="asset-details-meta-files-item-delete-button" style={{ textDecoration: 'none'}}>
                        <div
                            className="asset-details-prompt"
                            style={{ marginTop: '2px', textDecoration: 'none' }}
                        >{`Platform: all`} <HiChevronDown
                            style={{ width: '16px', height: '16px', marginTop: '3px' }}
                        /></div>
                      </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {getPossibleNewPlatforms('all', activeAsset.file.id)}
                    </Dropdown.Menu>
                  </Dropdown>


                  {activeAsset.file.size ? (
                    <div
                      className="asset-details-prompt"
                      style={{ marginTop: '2px' }}
                    >
                      {`${byteSize(activeAsset.file.size)}`}
                    </div>
                  ) : null}
                </div>
                <div className="file-delete-button">
                  <button
                      className="asset-details-meta-files-item-delete-button"
                      onClick={() => {
                        setShowDeleteFileModal(true);
                        setDeletingFileId(activeAsset.file.id);
                      }}
                  >
                    <FiTrash2 style={{ width: '24px', height: '24px', color: '#9C9EA4' }} />
                  </button>
                </div>
              </div>
            ) : null}
            {
              activeAsset.file && activeAsset.androidFile ?
                  <div className="asset-file-separator" />
                  : null
            }
            {activeAsset.androidFile ? (
              <div className="asset-details-meta-files-item">
                <div className="asset-details-meta-files-item-props">
                  <div
                    className="asset-details-meta-text"
                    style={{ maxWidth: '160px' }}
                  >
                    <a
                      href={activeAsset.androidFile.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {fileNameParser(activeAsset.androidFile.url)}
                    </a>
                  </div>

                  <Dropdown
                      onToggle={(isOpen) => {
                        setOpenDeleteContext(isOpen);
                      }}
                  >
                    <Dropdown.Toggle as={CustomToggleFiles}>
                      <button className="asset-details-meta-files-item-delete-button" style={{ textDecoration: 'none'}}>
                        <div
                            className="asset-details-prompt"
                            style={{ marginTop: '2px', textDecoration: 'none' }}
                        >{`Platform: Android`} <HiChevronDown
                            style={{ width: '16px', height: '16px', marginTop: '3px' }}
                        /></div>
                      </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {getPossibleNewPlatforms('android', activeAsset.androidFile.id)}
                    </Dropdown.Menu>
                  </Dropdown>

                  {activeAsset.androidFile.size ? (
                    <div
                      className="asset-details-prompt"
                      style={{ marginTop: '2px' }}
                    >
                      {`${byteSize(activeAsset.androidFile.size)}`}
                    </div>
                  ) : null}
                </div>
                <div className="file-delete-button">
                  <button
                      className="asset-details-meta-files-item-delete-button"
                      onClick={() => {
                        setShowDeleteFileModal(true);
                        setDeletingFileId(activeAsset.androidFile.id);
                      }}
                  >
                    <FiTrash2 style={{ width: '24px', height: '24px', color: '#9C9EA4' }} />
                  </button>
                </div>
              </div>
            ) : null}
            {
              (activeAsset.file || activeAsset.androidFile) && activeAsset.iosFile ?
                  <div className="asset-file-separator" />
                  : null
            }
            {activeAsset.iosFile ? (
              <div className="asset-details-meta-files-item">
                <div className="asset-details-meta-files-item-props">
                  <div
                    className="asset-details-meta-text"
                    style={{ maxWidth: '160px' }}
                  >
                    <a
                      href={activeAsset.iosFile.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {fileNameParser(activeAsset.iosFile.url)}
                    </a>
                  </div>

                  <Dropdown
                      onToggle={(isOpen) => {
                        setOpenDeleteContext(isOpen);
                      }}
                  >
                    <Dropdown.Toggle as={CustomToggleFiles}>
                      <button className="asset-details-meta-files-item-delete-button" style={{ textDecoration: 'none'}}>
                        <div
                            className="asset-details-prompt"
                            style={{ marginTop: '2px', textDecoration: 'none' }}
                        >{`Platform: iOS`} <HiChevronDown
                            style={{ width: '16px', height: '16px', marginTop: '3px' }}
                        /></div>
                      </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {getPossibleNewPlatforms('ios', activeAsset.iosFile.id)}
                    </Dropdown.Menu>
                  </Dropdown>

                  {activeAsset.iosFile.size ? (
                    <div
                      className="asset-details-prompt"
                      style={{ marginTop: '2px' }}
                    >
                      {`${byteSize(activeAsset.iosFile.size)}`}
                    </div>
                  ) : null}
                </div>
                <div className="file-delete-button">
                  <button
                      className="asset-details-meta-files-item-delete-button"
                      onClick={() => {
                        setShowDeleteFileModal(true);
                        setDeletingFileId(activeAsset.iosFile.id);
                      }}
                  >
                    <FiTrash2 style={{ width: '24px', height: '24px', color: '#9C9EA4' }} />
                  </button>
                </div>
              </div>
            ) : null}
            {activeAsset.type !== 'label' ?
                <div className="asset-details-meta-files-add">
                  <input
                      id="myInput"
                      type="file"
                      ref={fileInputRef}
                      style={{display: 'none'}}
                      onChange={(event) => handleFileInput(event)}
                  />
                  {props.uploads.loading ||
                  uploadingSpinner ||
                  props.assets.loading ? (
                      <ClipLoader size={24} color="#000000"/>
                  ) : (
                      <Dropdown
                          onToggle={(isOpen) => {
                            setOpenDeleteContext(isOpen);
                          }}
                      >
                        <Dropdown.Toggle as={CustomToggleFiles}>
                          <button className="asset-details-meta-files-item-delete-button">
                            Add or replace file
                          </button>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                              key="all"
                              eventKey="all"
                              onClick={() => {
                                setSelectedFilePlatform('all');
                                fileInputRef.current.click();
                              }}
                          >
                            All
                          </Dropdown.Item>
                          <Dropdown.Item
                              key="android"
                              eventKey="android"
                              onClick={() => {
                                setSelectedFilePlatform('android');
                                fileInputRef.current.click();
                              }}
                          >
                            Android
                          </Dropdown.Item>
                          <Dropdown.Item
                              key="ios"
                              eventKey="ios"
                              onClick={() => {
                                setSelectedFilePlatform('ios');
                                fileInputRef.current.click();
                              }}
                          >
                            iOS
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                  )}
                </div>
                :
                null
            }
          </div>
        </div>
            : null}
        {activeAsset.type === 'label' ?
            <div style={{ width: '100%'}}>
            <div className="asset-details-meta-item" style={{ justifyContent: "space-between"}}>
              <input
                  id="myMediaFileInput"
                  type="file"
                  ref={mediaFileInput}
                  style={{display: 'none'}}
                  onChange={(event) => handleMediaFileInput(event)}
              />
              <div className="asset-details-prompt">Files</div>
              {props.uploads.loading ||
              uploadingSpinner ||
              props.assets.loading ? (
                      <ClipLoader size={24} color="#000000"/>
                  ) :
                  <button className="asset-details-meta-files-item-delete-button element-details-asset-add"
                          onClick={() => {
                            mediaFileInput.current.click();
                          }}
                  >
                    Add file
                  </button>
              }
            </div>
              <div className="row" style={{ width: '100%' }}>
                {activeAsset.mediaAttachments.map(media => {

                  return(
                      <div className="col-md-4" style={{ marginTop: '8px' }}>
                        <div className="asset-details-media-file-wrap">
                          <div className="asset-details-media-img">
                            {media.mediaType.includes('image') && media.thumbnailUrl ?
                                <img src={media.thumbnailUrl} style={{ width: '100%', height: '70px', objectFit: 'cover', borderRadius: '4px' }}>
                                </img>
                                :
                                null
                            }
                            {media.mediaType.includes('video') && media.thumbnailUrl ?
                                <div style={{ position: 'relative' }}>
                                <video src={media.url} style={{ width: '100%', height: '70px', objectFit: 'cover', borderRadius: '4px' }}>
                                </video>
                                  <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <a href={media.url} target="_blank" rel="noreferrer"><img alt='play' src={videoPlayIcon} /></a>
                                  </div>
                                </div>
                                :
                                null
                            }
                          </div>
                          <div
                              className="asset-details-meta-text"
                              style={{ maxWidth: '110px' }}
                          >
                            <a
                                href={media.url}
                                target="_blank"
                                rel="noreferrer"
                            >
                              {fileNameParser(media.url)}
                            </a>
                          </div>
                          {media.size ? (
                              <div
                                  className="asset-details-prompt"
                                  style={{ marginTop: '2px' }}
                              >
                                {`${byteSize(media.size)}`}
                              </div>
                          ) : null}
                        </div>
                      </div>
                  )})
                }
              </div>
            </div>
            : null}
      </div>
      <Modal
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header style={{ justifyContent: 'flex-end' }}>
          <MdClose onClick={() => setShowDeleteModal(false)}></MdClose>
        </Modal.Header>
        <Modal.Title col-12>Delete asset?</Modal.Title>
        <Modal.Body>
          {activeAsset.file ? (
            <ModalDetails selectedAsset={activeAsset} file={activeAsset.file} />
          ) : null}
          {activeAsset.androidFile ? (
            <ModalDetails
              selectedAsset={activeAsset}
              file={activeAsset.androidFile}
            />
          ) : null}
          {activeAsset.iosFile ? (
            <ModalDetails
              selectedAsset={activeAsset}
              file={activeAsset.iosFile}
            />
          ) : null}

          <div className="row">
            <div className="col-md-6">
              <Button
                className="btn-delete"
                variant="danger"
                block
                type="submit"
                disabled={false}
                onClick={() => {
                  props.deleteAssetDispatch(activeAsset.id).then((res) => {
                    console.log('delete success');
                    history.push('/dashboard/assets');
                    props.getAssetsDispatch();
                  });
                }}
              >
                Delete asset
              </Button>
            </div>
            <div className="col-md-6">
              <Button
                className="btn-cancel"
                block
                variant="danger"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
          centered
          show={showDeleteFileModal}
          onHide={() => {
            setShowDeleteFileModal(false);
            setDeletingFileId(null);
          }}
      >
        <Modal.Header style={{ justifyContent: 'flex-end' }}>
          <MdClose onClick={() => {
            setShowDeleteFileModal(false);
            setDeletingFileId(null);
          }}></MdClose>
        </Modal.Header>
        <Modal.Title col-12>Delete file?</Modal.Title>
        <Modal.Body>
          {activeAsset.file && activeAsset.file.id === deletingFileId ? (
              <ModalDetails selectedAsset={activeAsset} file={activeAsset.file} />
          ) : null}
          {activeAsset.androidFile && activeAsset.androidFile.id === deletingFileId ? (
              <ModalDetails
                  selectedAsset={activeAsset}
                  file={activeAsset.androidFile}
              />
          ) : null}
          {activeAsset.iosFile && activeAsset.iosFile.id === deletingFileId ? (
              <ModalDetails
                  selectedAsset={activeAsset}
                  file={activeAsset.iosFile}
              />
          ) : null}

          <div className="row">
            <div className="col-md-6">
              <Button
                  className="btn-delete"
                  variant="danger"
                  block
                  type="submit"
                  disabled={false}
                  onClick={() => {
                    props.deleteAssetFileDispatch(activeAsset.id, deletingFileId).then((res) => {
                      setDeletingFileId(null);
                      setShowDeleteFileModal(false);
                      props.getAssetsDispatch();
                    });
                  }}
              >
                {
                  props.assets.loading ? <ClipLoader size={24} color="#000000"/> : "Delete asset"
                }
              </Button>
            </div>
            <div className="col-md-6">
              <Button
                  className="btn-cancel"
                  block

                  variant="danger"
                  onClick={() => setShowDeleteFileModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  assets: state.assets,
  uploads: state.uploads,
});

const mapDispatchToProps = {
  updateAssetDispatch,
  getAssetsDispatch,
  putUpdateAssetFile,
  postCreateAssetDispatch,
  postCreateUploadDispatch,
  deleteAssetDispatch,
  postAddMediaFileDispatch,
  putUpdateAssetFilePlatform,
  deleteAssetFileDispatch
};

AssetDetailsView.propTypes = {
  assets: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetDetailsView);
