import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  deleteAnchorTriggerDispatch,
  deleteMarkerTriggerDispatch,
  deleteMarkerTriggerImageDispatch,
} from '../../../../../actions/triggers';
import { getAssetsDispatch } from '../../../../../actions/assets';
import MenuItem from '../LeftColumn/MenuItem/MenuItem';

const AccountDetails = (props) => {
  const { path, selectedAccount, user } = props;

  return (
    <div>
      {selectedAccount ? (
        <div>
          <div className="row">
            <div className="col-md-6">{`ID: `}</div>
            <div className="col-md-6">{`${selectedAccount.id}`}</div>
          </div>
          <div className="row">
            <div className="col-md-6">{`Name: `}</div>
            <div className="col-md-6">{`${selectedAccount.name}`}</div>
          </div>
          <div className="row">
            <div className="col-md-6">{`Spatial Anchors Account Id: `}</div>
            <div className="col-md-6">{`${selectedAccount.spatialAnchorsAccountId}`}</div>
          </div>

          {user.data.roles.includes('administrator') ? (
            <div className="row">
              <div className="col-md-6">
                <MenuItem
                  name="Add user"
                  redirect={`${path.split('/')[2]}/add_user`}
                />
              </div>
              <div className="col-6"></div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

AccountDetails.propTypes = {
  history: PropTypes.object,
  selectedAccount: PropTypes.object,
  user: PropTypes.object,
  path: PropTypes.string,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  deleteMarkerTriggerDispatch,
  deleteAnchorTriggerDispatch,
  getAssetsDispatch,
  deleteMarkerTriggerImageDispatch,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
