/* eslint-disable */
import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Login from '../views/Login/Login';
import Main from '../views/Dashboard/Dashboard';
import Scene from '../views/Dashboard/components/Scene';
import Navbar from '../views/common/Navbar/Nav';
import { isLoggedIn } from '../util/http';

export default function Navigation() {
  const history = useHistory();
  return (
    <BrowserRouter history={history}>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/dashboard/:slug/:objectId">
          <Main />
        </Route>
        <Route path="/dashboard/:slug">
          <Main />
        </Route>
        <Route path="/dashboard">
          <Main />
        </Route>
        <Route path="/scene">
          <Scene />
        </Route>
        <Route exact path="/">
          {isLoggedIn() ? (
            <Redirect to="/dashboard" />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
