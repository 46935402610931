import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingPane from '../../../../common/LoadingPane';
import MenuItem from '../LeftColumn/MenuItem/MenuItem';
import { Link, useParams } from 'react-router-dom';
import './UserInfo.css';

const UserInfo = (props) => {
  const { user, accounts, onAccountClick } = props;
  const { slug } = useParams();
  return (
    <div>
      {user.loading ? (
        <LoadingPane />
      ) : (
        <div>
          {user.data.roles ? (
            <div>
              <div style={{ marginTop: '30px' }} className="row">
                <div className="col-2">
                  {user.data.name ? <div style={styles.title}>Name</div> : null}
                  <div style={styles.title}>Email</div>
                  <div style={styles.title}>ID</div>
                  {user.data.roles ? (
                    <div style={styles.title}>Role</div>
                  ) : null}
                  <div style={styles.title}>Account</div>
                </div>
                <div className="col-2 label-column">
                  {user.data.name ? (
                    <div style={styles.label}>{user.data.name}</div>
                  ) : null}
                  <div style={styles.label}>{user.data.email}</div>
                  <div style={styles.label}>{user.data.id}</div>

                  {user.data.roles ? (
                    <div style={styles.label}>
                      {user.data.roles.map((role) => (
                        <div key={role}>{role}</div>
                      ))}
                    </div>
                  ) : null}
                  <div style={styles.label}>
                    {user.data.memberships.map((membership) => {
                      return (
                        <Link
                          to={`/dashboard/user/accounts/${membership.account.id}`}
                          onClick={() => {
                            onAccountClick(membership.account);
                            // history.push(`/dashboard/assets/${asset.id}`);
                          }}
                          key={membership.account.name}
                        >
                          <div>{membership.account.name}</div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
              {user.data.roles.includes('administrator') ? (
                <div className="row">
                  <div className="col-4">
                    <div style={styles.title}>All accounts:</div>
                  </div>
                  <div className="col-6">
                    <div style={styles.label}>
                      {accounts.data.map((account) => {
                        return (
                          <Link
                            to={`/dashboard/user/accounts/${account.id}`}
                            onClick={() => {
                              onAccountClick(account);
                              // history.push(`/dashboard/assets/${asset.id}`);
                            }}
                            key={account.name}
                          >
                            <div>{account.name}</div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
              {user.data.roles.includes('administrator') ? (
                <div style={{ marginTop: '90px' }}>
                  <div className="row">
                    <div className="col-4">
                      <MenuItem
                        name="Add account"
                        redirect={`${slug}/add_account`}
                      />
                    </div>
                    <div className="col-6"></div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const styles = {
  title: {
    marginBottom: '12px',
    marginLeft: '20px',
    fontSize: '18px',
    color: '#686A6F',
  },
  label: {
    marginLeft: '-15px',
    marginBottom: '12px',
    fontSize: '18px',
  },
};

const mapStateToProps = (state) => ({
  user: state.user,
  accounts: state.accounts,
});

const mapDispatchToProps = {};

UserInfo.propTypes = {
  user: PropTypes.object,
  accounts: PropTypes.object,
  onAccountClick: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
