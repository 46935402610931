import { actionCreator } from './common';
import http, { getAccessToken } from '../util/http';
import {
  DELETE_ASSET,
  DELETE_ASSET_FAIL,
  DELETE_ASSET_FILE,
  DELETE_ASSET_FILE_FAIL,
  DELETE_ASSET_FILE_SUCCESS,
  DELETE_ASSET_SUCCESS,
  GET_ASSETS,
  GET_ASSETS_FAIL,
  GET_ASSETS_SUCCESS,
  POST_ADD_ASSET_MEDIA_FILE,
  POST_ADD_ASSET_MEDIA_FILE_FAIL,
  POST_ADD_ASSET_MEDIA_FILE_SUCCESS,
  POST_CREATE_ASSET,
  POST_CREATE_ASSET_FAIL,
  POST_CREATE_ASSET_SUCCESS,
  POST_UPDATE_ASSET,
  POST_UPDATE_ASSET_FAIL,
  POST_UPDATE_ASSET_SUCCESS,
  PUT_UPDATE_ASSET_FILE,
  PUT_UPDATE_ASSET_FILE_FAIL,
  PUT_UPDATE_ASSET_FILE_SUCCESS,
  PUT_UPDATE_ASSET_PLATFORM,
  PUT_UPDATE_ASSET_PLATFORM_FAIL,
  PUT_UPDATE_ASSET_PLATFORM_SUCCESS,
} from './types';

export const getAssetsDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(GET_ASSETS, data));
    return http
      .get('assets', {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(actionCreator(GET_ASSETS_SUCCESS, response.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(GET_ASSETS_FAIL, error.response));
        // eslint-disable-next-line
          // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const postCreateAssetDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(POST_CREATE_ASSET, data));
    return http
      .post('assets', data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(POST_CREATE_ASSET_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        dispatch(actionCreator(POST_CREATE_ASSET_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const postAddMediaFileDispatch = (assetId, uploadId) => {
  return async (dispatch) => {
    dispatch(actionCreator(POST_ADD_ASSET_MEDIA_FILE));
    return http
      .post(
        `assets/${assetId}/files/media`,
        {
          uploadId,
        },
        {
          headers: {
            Authorization: getAccessToken(),
          },
        }
      )
      .then(function (response) {
        return dispatch(
          actionCreator(POST_ADD_ASSET_MEDIA_FILE_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        dispatch(actionCreator(POST_ADD_ASSET_MEDIA_FILE_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const postCreateEmptyAssetDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(POST_CREATE_ASSET, data));
    return http
      .post('assets/new', data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(POST_CREATE_ASSET_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        dispatch(actionCreator(POST_CREATE_ASSET_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const updateAssetDispatch = (id, data) => {
  return async (dispatch) => {
    dispatch(actionCreator(POST_UPDATE_ASSET, data));
    return http
      .put(`assets/${id}/update`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(POST_UPDATE_ASSET_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        dispatch(actionCreator(POST_UPDATE_ASSET_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const putUpdateAssetFile = (data, assetId) => {
  return async (dispatch) => {
    dispatch(actionCreator(PUT_UPDATE_ASSET_FILE, data));
    return http
      .put(`assets/${assetId}/files`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(PUT_UPDATE_ASSET_FILE_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        dispatch(actionCreator(PUT_UPDATE_ASSET_FILE_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const putUpdateAssetFilePlatform = (assetId, assetFileId, platform) => {
  return async (dispatch) => {
    dispatch(actionCreator(PUT_UPDATE_ASSET_PLATFORM));
    return http
      .put(
        `assets/${assetId}/files/platform`,
        {
          assetFileId,
          platform,
        },
        {
          headers: {
            Authorization: getAccessToken(),
          },
        }
      )
      .then(function (response) {
        return dispatch(
          actionCreator(PUT_UPDATE_ASSET_PLATFORM_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        dispatch(actionCreator(PUT_UPDATE_ASSET_PLATFORM_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const deleteAssetDispatch = (assetId) => {
  return async (dispatch) => {
    dispatch(actionCreator(DELETE_ASSET));
    return http
      .delete(`assets/${assetId}`, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(actionCreator(DELETE_ASSET_SUCCESS, response.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(DELETE_ASSET_FAIL, error.response));
        // eslint-disable-next-line
          // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const deleteAssetFileDispatch = (assetId, assetFileId) => {
  return async (dispatch) => {
    dispatch(actionCreator(DELETE_ASSET_FILE));
    return http
      .delete(`assets/${assetId}/files/${assetFileId}`, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(DELETE_ASSET_FILE_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(DELETE_ASSET_FILE_FAIL, error.response));
        // eslint-disable-next-line
          // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};
