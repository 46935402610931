import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const AssetModelPreview = (props) => {
  const { assetUrl } = props;

  const previewRef = useRef(null);
  const [previewWidth, setPreviewWidth] = useState(0);
  const [previewHeight, setPreviewHeight] = useState(0);

  const updatePreviewDimensions = () => {
    if (previewRef.current) {
      setPreviewWidth(previewRef.current.offsetWidth);
      setPreviewHeight(previewRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    updatePreviewDimensions();
    window.addEventListener('resize', updatePreviewDimensions);
  }, []);

  return (
    <div className="asset-details-preview" ref={previewRef}>
      <div>
        <model-viewer
          src={assetUrl}
          poster="https://icon-library.com/images/3d-model-icon/3d-model-icon-5.jpg"
          alt="A 3D model"
          shadow-intensity="1"
          camera-controls
          auto-rotate
          style={{
            width: previewWidth,
            height: previewHeight,
            backgroundColor: '#eaebee',
          }}
        ></model-viewer>
      </div>
    </div>
  );
};

AssetModelPreview.propTypes = {
  asset: PropTypes.object,
  assetUrl: PropTypes.string,
};

export default AssetModelPreview;
