import { actionCreator } from './common';
import {
  POST_CREATE_UPLOAD,
  POST_CREATE_UPLOAD_FAIL,
  POST_CREATE_UPLOAD_SUCCESS,
} from './types';
import http, { getAccessToken } from '../util/http';

export const postCreateUploadDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(POST_CREATE_UPLOAD, data));
    return http
      .post('uploads', data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(POST_CREATE_UPLOAD_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        dispatch(actionCreator(POST_CREATE_UPLOAD_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};
