import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNTS_SUCCESS,
} from '../actions/types';

const initialState = {
  loading: false,
  data: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
        data: action.payload,
      };
    case GET_ACCOUNTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
