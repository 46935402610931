import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem/MenuItem';
import Logout from './MenuItem/LogOutItem';
import './LeftColumn.css';
import realityMaker from '../../../../../img/reality_maker.svg';
import { useParams } from 'react-router-dom';

const LeftColumn = (props) => {
  const { slug, objectId } = useParams();

  return (
    <div>
      <div id="wrapper">
        <div id="sidebar-wrapper">
          <ul className="sidebar-nav">
            <li className="sidebar-brand">
              <div className="left-column-logo">
                <img
                  className="realitymaker-logo"
                  alt="Polygon logo"
                  src={realityMaker}
                />
              </div>
            </li>
            <li>
              <MenuItem
                name="Assets"
                redirect="assets"
                selected={slug === 'assets'}
              />
            </li>
            <li>
              <MenuItem
                name="Scenes"
                redirect="elements"
                selected={slug === 'elements'}
              />
            </li>
            <li>
              <MenuItem
                style
                name="Triggers"
                redirect="triggers"
                selected={slug === 'triggers'}
              />
            </li>
            <li>
              {objectId !== 'add' ? null : (
                <MenuItem name="Add" redirect={`/dashboard/${slug}/add`} />
              )}
            </li>
            <div className="logout-link">
              <li>
                <MenuItem
                  name="Profile"
                  redirect="user"
                  selected={slug === 'user'}
                />
              </li>
              <li>
                <Logout name="Log out" />
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

LeftColumn.propTypes = {
  path: PropTypes.string,
};

export default LeftColumn;
