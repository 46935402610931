import { actionCreator } from './common';
import http, { getAccessToken } from '../util/http';
import {
  DELETE_ELEMENT,
  DELETE_ELEMENT_ASSET,
  DELETE_ELEMENT_ASSET_FAIL,
  DELETE_ELEMENT_ASSET_SUCCESS,
  DELETE_ELEMENT_FAIL,
  DELETE_ELEMENT_SUCCESS,
  DELETE_ELEMENT_TRIGGER,
  DELETE_ELEMENT_TRIGGER_FAIL,
  DELETE_ELEMENT_TRIGGER_SUCCESS,
  GET_ELEMENTS,
  GET_ELEMENTS_FAIL,
  GET_ELEMENTS_SUCCESS,
  POST_ADD_ELEMENT,
  POST_ADD_ELEMENT_FAIL,
  POST_ADD_ELEMENT_SUCCESS,
  POST_ELEMENT_LINK_ASSET,
  POST_ELEMENT_LINK_ASSET_FAIL,
  POST_ELEMENT_LINK_ASSET_SUCCESS,
  PUT_ELEMENT_LINK_TRIGGER,
  PUT_ELEMENT_LINK_TRIGGER_FAIL,
  PUT_ELEMENT_LINK_TRIGGER_SUCCESS,
  PUT_UPDATE_ELEMENT,
  PUT_UPDATE_ELEMENT_FAIL,
  PUT_UPDATE_ELEMENT_SUCCESS,
} from './types';

export const getElementsDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(GET_ELEMENTS, data));
    return http
      .get('elements', {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(actionCreator(GET_ELEMENTS_SUCCESS, response.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(GET_ELEMENTS_FAIL, error));
        // eslint-disable-next-line
                // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const putUpdateElementDispatch = (data, elementId) => {
  console.log(data);
  return async (dispatch) => {
    dispatch(actionCreator(PUT_UPDATE_ELEMENT, data));
    return http
      .put(`elements/${elementId}`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(PUT_UPDATE_ELEMENT_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(PUT_UPDATE_ELEMENT_FAIL, error.response));
        // eslint-disable-next-line
          // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const deleteElementAssetDispatch = (elementId, relationId) => {
  return async (dispatch) => {
    dispatch(actionCreator(DELETE_ELEMENT_ASSET));
    return http
      .delete(`elements/${elementId}/relations/${relationId}`, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(DELETE_ELEMENT_ASSET_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(DELETE_ELEMENT_ASSET_FAIL, error.response));
        // eslint-disable-next-line
          // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const deleteElementTriggerDispatch = (elementId) => {
  return async (dispatch) => {
    dispatch(actionCreator(DELETE_ELEMENT_TRIGGER));
    return http
      .delete(`elements/${elementId}/triggers`, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(DELETE_ELEMENT_TRIGGER_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(DELETE_ELEMENT_TRIGGER_FAIL, error.response));
        // eslint-disable-next-line
          // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const postElementLinkAssetDispatch = (data, elementId) => {
  console.log(data);
  return async (dispatch) => {
    dispatch(actionCreator(POST_ELEMENT_LINK_ASSET, data));
    return http
      .post(`elements/${elementId}/relations`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(POST_ELEMENT_LINK_ASSET_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(POST_ELEMENT_LINK_ASSET_FAIL, error.response));
        // eslint-disable-next-line
          // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const putElementLinkTriggerDispatch = (elementId, triggerId) => {
  return async (dispatch) => {
    dispatch(actionCreator(PUT_ELEMENT_LINK_TRIGGER));
    return http
      .put(
        `elements/${elementId}/triggers/${triggerId}`,
        {},
        {
          headers: {
            Authorization: getAccessToken(),
          },
        }
      )
      .then(function (response) {
        return dispatch(
          actionCreator(PUT_ELEMENT_LINK_TRIGGER_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(PUT_ELEMENT_LINK_TRIGGER_FAIL, error.response));
        // eslint-disable-next-line
          // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const postAddElementDispatch = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch(actionCreator(POST_ADD_ELEMENT, data));
    return http
      .post(`elements`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(actionCreator(POST_ADD_ELEMENT_SUCCESS, response.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(POST_ADD_ELEMENT_FAIL, error.response));
        // eslint-disable-next-line
          // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const deleteElementDispatch = (elementId) => {
  return async (dispatch) => {
    dispatch(actionCreator(DELETE_ELEMENT));
    return http
      .delete(`elements/${elementId}`, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(actionCreator(DELETE_ELEMENT_SUCCESS, response.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(DELETE_ELEMENT_FAIL, error.response));
        // eslint-disable-next-line
          // alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};
