import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { useHistory } from 'react-router';
import ClipLoader from 'react-spinners/ClipLoader';
import { loginUserDispatch } from '../../actions/user';
import './login.css';
import realityMaker from './reality_maker_white.svg';
const Login = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
  }
  return (
    <div className="maincontainer">
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-md-6 bg-image">
            <div className="reality-maker">
              <img alt="Reality Maker logo" src={realityMaker} />
            </div>
          </div>
          <div className="col-md-6 right-side-container">
            <div className="login-container">
              <div className="contact-us">
                <div>
                  <a
                    style={{ color: '#0072EF' }}
                    href="https://lab.mobi/#Contact"
                  >
                    Contact us
                  </a>
                </div>
              </div>
              <div className="content-container d-flex align-items-center py-5">
                <div className="container col-md-4 login-form">
                  <div>
                    <div className="title">Log in</div>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <FormGroup controlId="email">
                      <FormLabel className="email-form">Email</FormLabel>
                      <FormControl
                        className="form"
                        autoFocus
                        type="email"
                        align="center"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup controlId="password">
                      <FormLabel className="password-form">Password</FormLabel>
                      <FormControl
                        className="form"
                        align="center"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                      />
                    </FormGroup>
                    <Button
                      block
                      disabled={!validateForm()}
                      type="submit"
                      onClick={() => {
                        props
                          .loginUserDispatch({
                            email,
                            password,
                          })
                          .then((res) => {
                            history.push('/dashboard/assets');
                          })
                          .catch((err) => {});
                      }}
                    >
                      {' '}
                      {!props.user.loading ? (
                        <div className="button-title">Log in</div>
                      ) : (
                        <ClipLoader size={24} color="#ffffff" />
                      )}
                    </Button>
                    <div className="forgot_password">
                      <a href="/">
                        <div className="forgot_password_text">
                          Forgot password?
                        </div>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginUserDispatch,
};

Login.propTypes = {
  loginUserDispatch: PropTypes.func,
  user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
