import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './AssetItem.css';

const renderName = (asset) => {
  if (asset.title) {
    return (
      <li className="list-item list-group-item flex-fill">{asset.title}</li>
    );
  } else {
    return (
      <li
        className="list-item list-group-item flex-fill"
        style={{ color: '#686A6F', fontStyle: 'italic' }}
      >
        Add name
      </li>
    );
  }
};

const AssetItem = (props) => {
  const { asset, selected, onClick } = props;
  return (
    <Link
      to={`/dashboard/assets/${asset.id}`}
      style={selected ? styles.highlight : styles.container}
      onClick={() => {
        onClick();
        // history.push(`/dashboard/assets/${asset.id}`);
      }}
    >
      <div
        style={
          window.location.href.includes(asset.id)
            ? { backgroundColor: 'rgba(26, 141, 255, 0.06)' }
            : {}
        }
      >
        <ul className="list-group list-group-horizontal">
          <li
            className="list-group-item flex-fill col-md-4"
            style={{
              display: 'flex',
              alignItems: 'center',
              overflowWrap: 'anywhere',
            }}
          >
            {renderName(asset)}
          </li>
          <li
            className="list-group-item flex-fill col-md-4"
            style={{ display: 'flex', alignItems: 'center' }}
          >{`${asset.type}`}</li>
          <li className="list-group-item flex-fill col-md-4">
            {asset.file || asset.type === 'label' ? (
              <li className="platform-column list-item  list-group-item flex-fill">{`all 
            `}</li>
            ) : null}
            {asset.androidFile ? (
              <li className="platform-column list-item list-group-item flex-fill">{`android
            `}</li>
            ) : null}
            {asset.iosFile ? (
              <li className="platform-column list-item list-group-item flex-fill">{`ios
            `}</li>
            ) : null}
          </li>
        </ul>
      </div>
    </Link>
  );
};

const styles = {
  container: {
    minHeight: '72px',
    width: '100%',
    borderRadius: '16px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '18px',
    paddingRight: '18px',
    marginBottom: '12px',
    textDecoration: 'none',
  },
  highlight: {
    minHeight: '72px',
    width: '100%',
    borderRadius: '16px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '18px',
    paddingRight: '18px',
    marginBottom: '12px',
    textDecoration: 'none',
  },
  label: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '18px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 400,
  },
};

AssetItem.propTypes = {
  name: PropTypes.string,
  redirect: PropTypes.string,
  asset: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AssetItem;
