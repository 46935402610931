import ClipLoader from 'react-spinners/ClipLoader';
import React from 'react';

const LoadingPane = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '300px',
      }}
    >
      <ClipLoader size={48} color="#000000" />
    </div>
  );
};

export default LoadingPane;
