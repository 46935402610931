/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown } from 'react-bootstrap';
import CustomDropdownToggle from '../../../common/CustomDropdownToggle';
import { MdClose, MdMoreHoriz } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import Switch from 'react-switch';
import {
  deleteAnchorTriggerDispatch,
  deleteMarkerTriggerDispatch,
  getTriggersDispatch,
  postUpdateMarkerTriggerDispatch,
  putUpdateAnchorTriggerDispatch,
} from '../../../../../../actions/triggers';
import Modal from 'react-bootstrap/Modal';

const TriggerDetailsView = (props) => {
  const { slug, objectId } = useParams();
  const history = useHistory();
  // eslint-disable-next-line
    const [showDeleteModal, setShowDeleteModal] = useState(false);

  const activeTrigger = props.triggers.data.find(
    (trigger) => trigger.id === objectId
  );

  const [triggerEnabled, setTriggerEnabled] = useState(true);

  useEffect(() => {
    if (activeTrigger) {
      setTriggerEnabled(activeTrigger.enabled);
    }
  }, [activeTrigger]);

  return (
    <div className="asset-details-container">
      <div className="asset-details-top">
        <Dropdown onToggle={(isOpen) => {}}>
          <Dropdown.Toggle as={CustomDropdownToggle}>
            <MdMoreHoriz style={{ height: '24px', width: '24px' }} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              key="delete"
              eventKey="delete"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete trigger
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <button
          className="asset-details-top-button"
          onClick={() => {
            history.push(`/dashboard/${slug}`);
          }}
        >
          <MdClose style={{ height: '24px', width: '24px' }} />
        </button>
      </div>

      <div className="asset-details-meta">
        <div className="asset-details-meta-item">
          <div className="asset-details-prompt">ID</div>
          <div className="asset-details-meta-text">{activeTrigger.id}</div>
        </div>

        <div className="asset-details-meta-item">
          <div className="asset-details-prompt">Scenes</div>
          <div className="asset-details-meta-files">
            {activeTrigger.elements.map((el) => {
              return (
                <div key={el.id} className="element-details-meta-asset-item">
                  <a
                    href={`/dashboard/elements/${el.id}`}
                    className="asset-details-meta-text"
                  >
                    {el.name ? el.name : el.id}
                  </a>
                </div>
              );
            })}
          </div>
        </div>

        <div className="asset-details-meta-item">
          <div className="asset-details-prompt">Status</div>
          <div className="asset-details-meta-text">
            <Switch
              checked={triggerEnabled}
              onChange={(checked) => {
                setTriggerEnabled(checked);
                if (activeTrigger.type === 'anchor') {
                  props
                    .putUpdateAnchorTriggerDispatch(
                      {
                        ...activeTrigger,
                        enabled: checked,
                      },
                      activeTrigger.id
                    )
                    .then((res) => {
                      props.getTriggersDispatch();
                    });
                } else if (activeTrigger.type === 'marker') {
                  props
                    .postUpdateMarkerTriggerDispatch(
                      {
                        ...activeTrigger,
                        enabled: checked,
                      },
                      activeTrigger.id
                    )
                    .then((res) => {
                      props.getTriggersDispatch();
                    });
                }
              }}
              checkedIcon={false}
              uncheckedIcon={false}
              width={48}
              height={22}
              onColor="#11A972"
            />
          </div>
        </div>
      </div>

      <Modal
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header style={{ justifyContent: 'flex-end' }}>
          <MdClose onClick={() => setShowDeleteModal(false)}></MdClose>
        </Modal.Header>
        <Modal.Title col-12>Delete trigger?</Modal.Title>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <Button
                className="btn-delete"
                variant="danger"
                block
                type="submit"
                disabled={false}
                onClick={() => {
                  if (activeTrigger.type === 'anchor') {
                    props
                      .deleteAnchorTriggerDispatch(activeTrigger.id)
                      .then((res) => {
                        setShowDeleteModal(false);
                        history.push('/dashboard/triggers');
                        props.getAssetsDispatch();
                      });
                  } else if (activeTrigger.type === 'marker') {
                    props
                      .deleteMarkerTriggerDispatch(activeTrigger.id)
                      .then((res) => {
                        setShowDeleteModal(false);
                        history.push('/dashboard/triggers');
                        props.getAssetsDispatch();
                      });
                  } else {
                    setShowDeleteModal(false);
                  }
                }}
              >
                Delete scene
              </Button>
            </div>
            <div className="col-md-6">
              <Button
                className="btn-cancel"
                block
                variant="danger"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  triggers: state.triggers,
});

const mapDispatchToProps = {
  getTriggersDispatch,
  putUpdateAnchorTriggerDispatch,
  deleteAnchorTriggerDispatch,
  postUpdateMarkerTriggerDispatch,
  deleteMarkerTriggerDispatch,
};

export default connect(mapStateToProps, mapDispatchToProps)(TriggerDetailsView);
