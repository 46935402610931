import {
  GET_USER_SELF,
  GET_USER_SELF_FAIL,
  GET_USER_SELF_SUCCESS,
  LOG_IN,
  LOG_IN_FAIL,
  LOG_IN_SUCCESS,
  LOG_OUT,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_SUCCESS,
  SIGN_UP,
  SIGN_UP_FAIL,
  SIGN_UP_SUCCESS,
} from '../actions/types';

const initialState = {
  loading: false,
  authentication: {
    accessToken: '',
    refreshToken: '',
  },
  loginData: {},
  data: {},
  error: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_UP:
      return {
        ...state,
        loading: true,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
        user: { ...action.payload.user },
        authentication: {
          ...state.authentication,
          ...action.payload.authentication,
        },
      };
    case SIGN_UP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOG_IN:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case LOG_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
        loginData: { ...action.payload.user },
        data: { ...action.payload.user },
        authentication: {
          ...state.authentication,
          ...action.payload.authentication,
        },
      };
    case LOG_IN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        authentication: {
          accessToken: '',
          refreshToken: '',
        },
        user: {},
      };
    case LOG_OUT:
      return {
        ...initialState,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: {},
        authentication: {
          ...state.authentication,
          ...action.payload,
        },
      };
    case REFRESH_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USER_SELF:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case GET_USER_SELF_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_USER_SELF_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
