import React from 'react';
import { connect } from 'react-redux';

const Scene = (props) => {
  return <div>Scene</div>;
};

const mapStateToProps = (state) => {};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Scene);
