import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './DeletionModal.css';

const fileNameParser = (url) => {
  const split = url.split('/');
  return split[split.length - 1];
};

const ModalDetails = (props) => {
  const { file } = props;

  return (
    <div>
      <div
        className="file-name"
        style={{ overflowWrap: 'anywhere' }}
      >{`${fileNameParser(file.url)}`}</div>
      <div className="row">
        <div className="col-12">
          <div className="text-confirmation">
            <p>
              Are you sure you want to delete this asset? This action removes
              the asset from all the scenes it is used in.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

ModalDetails.propTypes = {
  selectedAsset: PropTypes.object,
  file: PropTypes.object,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDetails);
