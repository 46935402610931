import {
  GET_ACCOUNTS,
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNTS_SUCCESS,
  POST_ADD_USER_TO_ACCOUNT,
  POST_ADD_USER_TO_ACCOUNT_FAIL,
  POST_ADD_USER_TO_ACCOUNT_SUCCESS,
  POST_CREATE_ACCOUNT,
  POST_CREATE_ACCOUNT_FAIL,
  POST_CREATE_ACCOUNT_SUCCESS,
} from './types';
import http, { getAccessToken } from '../util/http';
import { actionCreator } from './common';

export const getAccountsDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(GET_ACCOUNTS, data));
    return http
      .get('accounts', {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        console.log(response.data);
        return dispatch(actionCreator(GET_ACCOUNTS_SUCCESS, response.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(GET_ACCOUNTS_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const postCreateAccountDispatch = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch(actionCreator(POST_CREATE_ACCOUNT, data));
    return http
      .post(`accounts`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(POST_CREATE_ACCOUNT_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        dispatch(actionCreator(POST_CREATE_ACCOUNT_FAIL, {}));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const postAddUserToAccount = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(POST_ADD_USER_TO_ACCOUNT, data));
    return http
      .post(
        `accounts/${data.accountId}/users/${data.userId}`,
        {
          role: data.role,
        },
        {
          headers: {
            Authorization: getAccessToken(),
          },
        }
      )
      .then(function (response) {
        return dispatch(
          actionCreator(POST_ADD_USER_TO_ACCOUNT_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(POST_ADD_USER_TO_ACCOUNT_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};
