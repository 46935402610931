import {
  GET_ASSETS,
  GET_ASSETS_FAIL,
  GET_ASSETS_SUCCESS,
  POST_CREATE_ASSET,
  POST_CREATE_ASSET_FAIL,
  POST_CREATE_ASSET_SUCCESS,
  PUT_UPDATE_ASSET_PLATFORM,
  PUT_UPDATE_ASSET_PLATFORM_FAIL,
  PUT_UPDATE_ASSET_PLATFORM_SUCCESS,
} from '../actions/types';

const initialState = {
  loading: false,
  data: [],
  error: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ASSETS:
      return {
        ...state,
        loading: true,
      };
    case GET_ASSETS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_ASSETS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case POST_CREATE_ASSET:
      return {
        ...state,
        loading: true,
      };
    case POST_CREATE_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case POST_CREATE_ASSET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PUT_UPDATE_ASSET_PLATFORM:
      return {
        ...state,
        loading: true,
      };
    case PUT_UPDATE_ASSET_PLATFORM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PUT_UPDATE_ASSET_PLATFORM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
}
