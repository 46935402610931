/* eslint-disable */
/* eslint-disable react/prop-types */
import React, {useEffect, useRef, useState} from 'react';
import { FileUploader } from "react-drag-drop-files";
import { connect } from 'react-redux';
import LoadingPane from '../../../../common/LoadingPane';
import ElementItem from './ElementItem';
import TriggerItem from './TriggerItem';
import AssetItem from './AssetItem';
import UserInfo from './UserInfo';
import './ElementItem.css';
import { useParams } from 'react-router-dom';
import {Button, Dropdown, Form} from 'react-bootstrap';
import './MiddleColumn.css';
import {
  getAssetsDispatch,
  postCreateAssetDispatch,
  postCreateEmptyAssetDispatch,
  putUpdateAssetFile,
  updateAssetDispatch,
} from '../../../../../actions/assets';
import { postCreateUploadDispatch } from '../../../../../actions/uploads';
import ClipLoader from 'react-spinners/ClipLoader';
import { useHistory } from 'react-router';
import Modal from "react-bootstrap/Modal";
import {MdClose} from "react-icons/md";
import {AiOutlineCloudUpload} from "react-icons/ai";
import {getElementsDispatch, postAddElementDispatch} from "../../../../../actions/elements";
import axios from "axios";
import { getUserSelfDispatch } from '../../../../../actions/user';

const fileTypes = ["JPG", "PNG", "GIF", "MP3", "MP4", "GLB", "USDZ", "FBX", "ZIP"];

function setFileType(originalFile, newType) {
  return new File([originalFile], originalFile.name, {
    type: newType,
    lastModified: originalFile.lastModified,
  });
}

// eslint-disable-next-line react/display-name
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line
    <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const MiddleColumn = (props) => {
  const {
    elements,
    triggers,
    assets,
    selectedElement,
    setSelectedElement,
    selectedTrigger,
    setSelectedTrigger,
    selectedAsset,
    setSelectedAsset,
    setSelectedAccount,
    getUserSelfDispatch
  } = props;

  const { slug } = useParams();

  const [assetSearchString, setAssetSearchString] = useState('');
  const [sceneSearchString, setSceneSearchString] = useState('');
  const [triggerSearchString, setTriggerSearchString] = useState('');

  const [uploadingSpinner, setUploadingSpinner] = useState(false);

  const history = useHistory();

  const [showCreateElementModal, setShowCreateElementModal] = useState(false);

  const [createdElementName, setShowCreatedElementName] = useState('');

  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (event) => {
    const fileInput = event.target.files[0];
    setSelectedFile(fileInput);
  };

  const handleFileDrop = (file) => {
    setSelectedFile(file);
  }

  const addOrReplaceFile = () => {
    console.log('file processing');
    setUploadingSpinner(true);
    let uploadableFile = selectedFile
    let type = uploadableFile.name.split('.').slice(-1)[0];
    if (uploadableFile.type !== '') {
      type = uploadableFile.type;
    }
    if (uploadableFile.name.split('.').slice(-1)[0] === 'glb') {
      type = 'model/gltf-binary';
      const setTypeInFile = setFileType(uploadableFile, 'model/gltf-binary');
      uploadableFile = setTypeInFile;
    }
    if (uploadableFile.name.split('.').slice(-1)[0] === 'gltf') {
      type = 'model/gltf+json';
    }
    if (uploadableFile.name.split('.').slice(-1)[0] === 'ab') {
      type = 'application/octet-stream';
    }
    if (uploadableFile.name.split('.').slice(-1)[0] === 'fbx') {
      type = 'model/fbx';
    }

    const replace = true;
    props
        .postCreateUploadDispatch({
          contentType: type,
          filename: uploadableFile.name,
        })
        .then((res) => {
          const file = uploadableFile;
          const data = new FormData();
          data.append('file', file);

          axios
              .put(res.payload.signedUrl, uploadableFile, {
                headers: {
                  'content-type': type,
                },
              })
              .then(function (cloudRes) {
                console.log(cloudRes);
                if (replace) {
                  props
                      .postCreateAssetDispatch(
                          {
                            platform: 'all',
                            uploadId: res.payload.id,
                          },
                      )
                      .then((asset) => {
                        props.getAssetsDispatch().then((all) => {
                          setUploadingSpinner(false);
                          history.push(`/dashboard/assets/${asset.payload.id}`);
                        });
                      })
                      .catch((assetErr) => {
                        setUploadingSpinner(false);
                        alert('upload failed');
                      });
                }
              })
              .catch(function (err) {
                console.log(err);
                console.log(JSON.stringify(err));
                setUploadingSpinner(false);
                alert('upload failed');
              });
        })
        .catch((err) => {
          alert('upload error');
        });
  };


  useEffect(() => {
    if (selectedFile) {
      addOrReplaceFile();
    }
  }, [selectedFile]);


  const assetFilter = (searchables) => {
    if (assetSearchString !== '') {
      if (searchables.title) {
        return searchables.title
            .toLowerCase()
            .startsWith(assetSearchString.toLowerCase());
      } else if (searchables.description) {
        return searchables.description
          .toLowerCase()
          .startsWith(assetSearchString.toLowerCase());
      } else if (searchables.file) {
        return searchables.file.url
          .toLowerCase()
          .includes(assetSearchString.toLowerCase());
      } else if (searchables.androidFile) {
        return searchables.androidFile.url
          .toLowerCase()
          .includes(assetSearchString.toLowerCase());
      } else if (searchables.iosFile) {
        return searchables.iosFile.url
          .toLowerCase()
          .includes(assetSearchString.toLowerCase());
      }
    } else return true;
  };

  switch (slug) {
    case 'elements': {
      const originalElement = selectedElement
        ? elements.data.find((element) => element.id === selectedElement.id)
        : {
            enabled: false,
            name: '',
          };
      return (
        <div>
          <div className="header">
            <div className="element-header-container">
              <div className="element-header-container-title-add">
              <h2>Scenes</h2>
                <button
                    className="element-header-container-title-add-button"
                    onClick={() => {
                      setShowCreateElementModal(true);
                    }}
                >
                        <span className="element-header-container-title-add-button-text">
                          + New
                        </span>
                </button>
              </div>
              <div>
                <input
                  className="element-header-search-input"
                  type="search"
                  placeholder="Search"
                  value={sceneSearchString}
                  onChange={(event) => setSceneSearchString(event.target.value)}
                />
              </div>
            </div>
            <ul className="list-group list-group-horizontal">
              <li className="lable list-group-item  col-md-4">{`Scene names`}</li>
              <li className="lable list-group-item  col-md-4">{`Status`}</li>
              <li className="scene-last-modified lable list-group-item  col-md-4">{`Last modified`}</li>
            </ul>
          </div>
          {elements.loading && elements.data.length === 0 ? (
            <LoadingPane />
          ) : (
            <div>
              {elements.data
                .filter((searchables) =>
                  sceneSearchString !== ''
                    ? searchables.name
                        .toLowerCase()
                        .startsWith(sceneSearchString.toLowerCase())
                    : true
                )
                .map((element) => {
                  return (
                    <ElementItem
                      key={element.id}
                      element={element}
                      selected={
                        selectedElement
                          ? selectedElement.id === element.id
                          : false
                      }
                      onClick={() => setSelectedElement(element)}
                    />
                  );
                })}
            </div>
          )}




          <Modal
              centered
              show={showCreateElementModal}
              onHide={() => setShowCreateElementModal(false)}
          >
            <Modal.Header style={{ justifyContent: 'flex-end'}}>
              <MdClose style={{ color: '#686A6F', width: '24px', height: '24px'}} onClick={() => setShowCreateElementModal(false)}></MdClose>
            </Modal.Header>
            <Modal.Title col-12 style={{ fontWeight: 600 }}>Create new scene</Modal.Title>
            <Modal.Body style={{ padding: '40px' }}>
              <div className="row" style={{ padding: 0, margin: 0 }}>
                <div className="col-md-12" style={{ padding: 0, margin: 0 }}>
                  <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label style={{
                        color: '#686A6F',
                        fontWeight: 500,
                        fontSize: '14px',
                        lineHeight: '16px'
                      }}>Name</Form.Label>
                      <Form.Control type="text" value={createdElementName} onChange={(event) => setShowCreatedElementName(event.target.value)}/>
                    </Form.Group>
                  </Form>
                </div>

              </div>

              <div className="row" style={{ padding: 0, margin: 0 }}>
                <div className="col-md-12" style={{ padding: 0, margin: 0 }}>
                  <Button
                      variant="primary"
                      block
                      type="submit"
                      disabled={false}
                      style={{ width: "100%", fontWeight: 600}}
                      onClick={() => {
                        props.postAddElementDispatch(
                            {
                              enabled: true,
                              name: createdElementName,
                            }
                        ).then(res => {
                          props.getElementsDispatch().then(res2 => {
                            setShowCreateElementModal(false);
                            history.push(`/dashboard/elements/${res.payload.id}`)
                          });
                        })
                      }}
                  >
                    {props.elements.loading ?
                        <ClipLoader
                            size={24}
                            color="#ffffff"
                            style={{ padding: 0 }}
                        />
                        :
                        "Create scene"
                    }
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      );
    }
    case 'triggers': {
      return (
        <div>
          <div className="header">
            <div className="element-header-container">
              <h2>Triggers</h2>
              <div>
                <input
                  className="element-header-search-input"
                  type="search"
                  placeholder="Search"
                  value={triggerSearchString}
                  onChange={(event) =>
                    setTriggerSearchString(event.target.value)
                  }
                />
              </div>
            </div>
            <ul className="list-group list-group-horizontal">
              <li className="lable list-group-item  col-md-4">{`Trigger name`}</li>
              <li className="lable list-group-item  col-md-4">{`Type`}</li>
              <li className="lable list-group-item  col-md-4">{`Status`}</li>
            </ul>
          </div>
          {triggers.loading && triggers.data.length === 0 ? (
            <LoadingPane />
          ) : (
            <div>
              {triggers.data
                .filter((searchables) =>
                  triggerSearchString !== ''
                    ? searchables.id
                        .toLowerCase()
                        .startsWith(triggerSearchString.toLowerCase())
                    : true
                )
                .map((trigger) => {
                  return (
                    <TriggerItem
                      key={trigger.id}
                      trigger={trigger}
                      selected={
                        selectedTrigger
                          ? selectedTrigger.id === trigger.id
                          : false
                      }
                      onClick={() => {
                        setSelectedTrigger(trigger);
                      }}
                    />
                  );
                })}
            </div>
          )}
        </div>
      );
    }
    case 'assets': {
      return (
        <div>
          <div className="header">
            <div className="element-header-container">
              <div className="element-header-container-title-add">
                <input
                    id="myFileUploadInput"
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={(event) => handleFileInput(event)}
                />
                <h2>Assets</h2>
                {uploadingSpinner ? (
                  <ClipLoader
                    style={{ marginLeft: '24px' }}
                    size={24}
                    color="#000000"
                  />
                ) : (
                  <Dropdown onToggle={(isOpen) => {}}>
                    <Dropdown.Toggle as={CustomToggle}>
                      <button
                        className="element-header-container-title-add-button"
                        onClick={() => {}}
                      >
                        <span className="element-header-container-title-add-button-text">
                          + New
                        </span>
                      </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        key="file"
                        eventKey="file"
                        onClick={() => {
                          //getUserSelfDispatch is used to check if token is still valid
                          getUserSelfDispatch().then((user) => {
                            fileInputRef.current.click()
                          });
                        }}
                      >
                        File upload
                      </Dropdown.Item>
                      <Dropdown.Item
                        key="label"
                        eventKey="label"
                        onClick={() => {
                          setUploadingSpinner(true);
                          props.postCreateEmptyAssetDispatch({}).then((res) => {
                            console.log(res);
                            props.getAssetsDispatch().then((all) => {
                              setUploadingSpinner(false);
                              history.push(
                                `/dashboard/assets/${res.payload.id}`
                              );
                            });
                          });
                        }}
                      >
                        New label
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              <div>
                <input
                  className="element-header-search-input"
                  type="search"
                  placeholder="Search"
                  value={assetSearchString}
                  onChange={(event) => setAssetSearchString(event.target.value)}
                />
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <FileUploader
                  classes="asset-upload-area-base"
                  style={{ width: '100%' }}
                  handleChange={() => {}}
                  name="file"
                  types={fileTypes}
                  onDrop={(file) => {
                    //getUserSelfDispatch is used to check if token is still valid
                    getUserSelfDispatch().then((user) => {
                      handleFileDrop(file);
                    })
                  }}
                  onSelect={(file) => {
                    //getUserSelfDispatch is used to check if token is still valid
                    getUserSelfDispatch().then((user) => {
                      handleFileDrop(file);
                    })
                  }}
                  hoverTitle=" "
              >
                <div className="asset-upload-area">
                  <div className="asset-upload-prompt">
                    <AiOutlineCloudUpload style={{ width: '24px', height: '24px', color: '#0072EF'}} />
                    <span className="asset-upload-prompt-text">Drop file to create new asset</span>
                  </div>
                  <span className="asset-upload-prompt-browser">Or browse</span>
                </div>
              </FileUploader>
            </div>
            <ul className="list-group list-group-horizontal">
              <li className="lable list-group-item  col-md-4">
                <p className="asset-name">{`Asset name`}</p>
              </li>
              <li className="lable list-group-item  col-md-4">{`Type`}</li>
              <li className="lable list-group-item  col-md-4">
                <p className="asset-platform">{`Platform`}</p>
              </li>
            </ul>
          </div>
          {assets.loading && assets.data.length === 0 ? (
            <LoadingPane />
          ) : (
            <div>
              {assets.data
                .filter((searchables) => assetFilter(searchables))
                .map((asset) => {
                  return (
                    <AssetItem
                      key={asset.id}
                      asset={asset}
                      selected={
                        selectedAsset ? selectedAsset.id === asset.id : false
                      }
                      onClick={() => setSelectedAsset(asset)}
                    />
                  );
                })}
            </div>
          )}
        </div>
      );
    }
    case 'user': {
      return (
        <div>
          <div className="header">
            <h2 className="profile-header-title">Profile</h2>
          </div>
          <div>
            <UserInfo onAccountClick={setSelectedAccount} />
          </div>
        </div>
      );
    }
    default: {
      return <div />;
    }
  }
};

const mapStateToProps = (state) => ({
  assets: state.assets,
  uploads: state.uploads,
});

const mapDispatchToProps = {
  updateAssetDispatch,
  getAssetsDispatch,
  putUpdateAssetFile,
  postCreateAssetDispatch,
  postCreateUploadDispatch,
  postCreateEmptyAssetDispatch,
  postAddElementDispatch,
  getElementsDispatch,
  getUserSelfDispatch
};

export default connect(mapStateToProps, mapDispatchToProps)(MiddleColumn);
