import { combineReducers } from 'redux';
import user from './user';
import elements from './elements';
import assets from './assets';
import triggers from './triggers';
import uploads from './uploads';
import accounts from './accounts';

export default combineReducers({
  user,
  triggers,
  assets,
  elements,
  uploads,
  accounts,
});
