const styles = {
  button: {
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '16px',
      height: 38,
    },
  },
  detailsContainer: {
    borderRadius: '16px',
    backgroundColor: 'rgba(255,255,255,1)',
    marginBottom: '10px',
    marginTop: '-30px',
    marginLeft: '10px',
    maxWidth: '380px',
  },
};

export default styles;
