import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import './styles.css';
import { Container } from 'react-bootstrap';
import { logoutUserDispatch } from '../../../actions/user';

const Nav = (props) => {
  return (
    <Navbar fixed="top" variant="dark">
      <Container>
        <Navbar.Brand href="/"></Navbar.Brand>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  logoutUserDispatch,
};

Nav.propTypes = {
  logoutUserDispatch: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
