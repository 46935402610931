import {
  GET_USER_SELF,
  GET_USER_SELF_FAIL,
  GET_USER_SELF_SUCCESS,
  LOG_IN,
  LOG_IN_FAIL,
  LOG_IN_SUCCESS,
  LOG_OUT,
  POST_CREATE_USER,
  POST_CREATE_USER_FAIL,
  POST_CREATE_USER_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAIL,
  REFRESH_TOKEN_SUCCESS,
  SIGN_UP,
  SIGN_UP_FAIL,
  SIGN_UP_SUCCESS,
} from './types';
import http, { getAccessToken, getRefreshToken } from '../util/http';
import { actionCreator } from './common';

export const registerUserDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(SIGN_UP, data));
    return http
      .post('users', {
        email: data.email,
        password: data.password,
      })
      .then(function (response) {
        return dispatch(actionCreator(SIGN_UP_SUCCESS, response.data));
      })
      .catch(function (error) {
        dispatch(actionCreator(SIGN_UP_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const loginUserDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(LOG_IN, data));
    return http
      .post('authentication/login', {
        email: data.email,
        password: data.password,
      })
      .then(function (response) {
        return dispatch(actionCreator(LOG_IN_SUCCESS, response.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(LOG_IN_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const refreshAccessTokenDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(REFRESH_TOKEN, data));
    return http
      .post('authentication/token', {
        refresh_token: getRefreshToken(),
      })
      .then(function (response) {
        return dispatch(actionCreator(REFRESH_TOKEN_SUCCESS, response.data));
      })
      .catch(function (error) {
        dispatch(actionCreator(REFRESH_TOKEN_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const logoutUserDispatch = () => {
  return async (dispatch) => {
    dispatch(actionCreator(LOG_OUT));
  };
};

export const getUserSelfDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(GET_USER_SELF, data));
    return http
      .get('users/self', {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(actionCreator(GET_USER_SELF_SUCCESS, response.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(GET_USER_SELF_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const postCreateUserDispatch = (data) => {
  console.log(data);
  return async (dispatch) => {
    dispatch(actionCreator(POST_CREATE_USER, data));
    return http
      .post('users', data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(actionCreator(POST_CREATE_USER_SUCCESS, response.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(POST_CREATE_USER_FAIL, error.response));
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};
