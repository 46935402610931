import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styles from './styles';
import { getAccountsDispatch } from '../../../../../actions/accounts';

const AddUser = (props) => {
  const {
    history,
    addNewUser,
    linkUserToAccount,
    accounts,
    getAccountsDispatch,
  } = props;

  const roles = ['administrator', 'editor', 'viewer'];

  const [userDetails, setUserDetails] = useState({ email: '', password: '' });
  const [selectedAccount, setSelectedAccount] = useState('');
  const [selectedRole, setSelectedRole] = useState('');

  console.log('add user');
  useEffect(() => {
    getAccountsDispatch();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="row">
        <div>
          <div className="row" style={{ marginTop: '12px' }}>
            <div className="col-md-12">
              {`Email: `}
              <InputGroup>
                <FormControl
                  aria-label="Text box for adding user email"
                  value={userDetails.email}
                  onChange={(event) => {
                    setUserDetails({
                      ...userDetails,
                      email: event.target.value,
                    });
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="row" style={{ marginTop: '12px' }}>
            <div className="col-md-12">
              {`Password: `}
              <InputGroup>
                <FormControl
                  aria-label="Text box for adding user password"
                  value={userDetails.password}
                  onChange={(event) => {
                    setUserDetails({
                      ...userDetails,
                      password: event.target.value,
                    });
                  }}
                />
              </InputGroup>
            </div>
          </div>
          <div className="row" style={{ marginTop: '12px' }}>
            {`Link account: `}
          </div>
          <div className="row">
            <div className="col-md-6">
              <DropdownButton
                id="dropdown-basic-button"
                title="Select account"
                onSelect={(value) => {
                  setSelectedAccount(value);
                }}
              >
                {accounts.data ? (
                  <div>
                    {accounts.data.map((account) => {
                      return (
                        <Dropdown.Item eventKey={account.id} key={account.id}>
                          {`${account.name} - ${account.id}`}
                        </Dropdown.Item>
                      );
                    })}
                  </div>
                ) : null}
              </DropdownButton>
            </div>
            <div className="col-md-6">{`${selectedAccount}`}</div>
          </div>
          <div className="row" style={{ marginTop: '12px' }}>
            {`Select role: `}
          </div>
          <div className="row">
            <div className="col-md-6">
              <DropdownButton
                id="dropdown-basic-button"
                title="Select role"
                onSelect={(value) => {
                  setSelectedRole(value);
                }}
              >
                {roles ? (
                  <div>
                    {roles.map((role) => {
                      return (
                        <Dropdown.Item eventKey={role} key={role}>
                          {role}
                        </Dropdown.Item>
                      );
                    })}
                  </div>
                ) : null}
              </DropdownButton>
            </div>
            <div className="col-md-6">{`${selectedRole}`}</div>
          </div>
          <div className="row" style={{ marginTop: '42px' }}>
            <div className="col-md-12">
              <Button
                style={styles.buttonContainer}
                block
                type="submit"
                disabled={
                  userDetails.password === '' ||
                  userDetails.email === '' ||
                  selectedAccount === '' ||
                  selectedRole === ''
                }
                onClick={() => {
                  console.log(userDetails);
                  addNewUser({
                    email: userDetails.email,
                    password: userDetails.password,
                  }).then((res) => {
                    console.log(res.payload);
                    const newUser = res.payload.user;
                    linkUserToAccount({
                      accountId: selectedAccount,
                      userId: newUser.id,
                      role: selectedRole,
                    });
                    history.push(`/dashboard/user`);
                  });
                }}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddUser.propTypes = {
  history: PropTypes.object,
  selectedAccount: PropTypes.object,
  setSelectedAccount: PropTypes.func,
  addNewUser: PropTypes.func,
  linkUserToAccount: PropTypes.func,
  getAccountsDispatch: PropTypes.func,
  accounts: PropTypes.object,
};

const mapDispatchToProps = {
  getAccountsDispatch,
};

const mapStateToProps = (state) => ({
  accounts: state.accounts,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
