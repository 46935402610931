import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styles from './styles';
import {
  deleteAnchorTriggerDispatch,
  deleteMarkerTriggerDispatch,
  deleteMarkerTriggerImageDispatch,
} from '../../../../../actions/triggers';
import { getAssetsDispatch } from '../../../../../actions/assets';
import { Link } from 'react-router-dom';
import { MdClose } from 'react-icons/md';

const TriggerDetails = (props) => {
  const {
    history,
    assets,
    selectedTrigger,
    setSelectedTrigger,
    fetchTriggers,
    fetchElements,
    updateMarkerTrigger,
    addImageToMarkerTrigger,
    updateAnchorTrigger,
  } = props;

  useEffect(() => {
    props.getAssetsDispatch().then((res) => {});
    // eslint-disable-next-line
  }, assets);

  console.log(assets);
  return (
    <div>
      <div className="row">
        <div className="col-md-6">{`ID: ${selectedTrigger.id}`}</div>
        <div className="col-md-6">
          <Button
            style={styles.buttonContainer}
            block
            type="submit"
            disabled={false}
            onClick={() => {
              if (selectedTrigger.type === 'marker') {
                updateMarkerTrigger(
                  {
                    enabled: selectedTrigger.enabled,
                    markerSizeCm: selectedTrigger.markerSizeCm,
                  },
                  selectedTrigger.id
                ).then((res) => {
                  fetchTriggers().then((fetch) =>
                    console.log('update success')
                  );
                });
              } else {
                updateAnchorTrigger(
                  {
                    enabled: selectedTrigger.enabled,
                    anchorId: selectedTrigger.anchorId,
                  },
                  selectedTrigger.id
                ).then((res) => {
                  fetchTriggers().then((fetch) =>
                    console.log('update success')
                  );
                });
              }
            }}
          >
            Update
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {`Enabled: `}
          <InputGroup>
            <InputGroup.Checkbox
              aria-label="Checkbox for enabling trigger"
              checked={selectedTrigger.enabled}
              onChange={(event) => {
                console.log(event.target.checked);
                setSelectedTrigger({
                  ...selectedTrigger,
                  enabled: event.target.checked,
                });
              }}
            />
          </InputGroup>
        </div>
      </div>

      {selectedTrigger.type === 'marker' ? (
        <div className="row" style={{ marginTop: '12px' }}>
          <div className="col-md-12">
            {`Marker size (cm): `}
            <InputGroup>
              <FormControl
                aria-label="Text box for updating element"
                value={selectedTrigger.markerSizeCm}
                onChange={(event) => {
                  console.log(event.target.value);
                  setSelectedTrigger({
                    ...selectedTrigger,
                    markerSizeCm: event.target.value,
                  });
                }}
              />
            </InputGroup>
          </div>
        </div>
      ) : null}
      {selectedTrigger.type === 'marker' ? (
        <div className="row" style={{ marginTop: '12px' }}>
          <div className="col-md-12">
            {`Link asset: `}
            <DropdownButton
              id="dropdown-basic-button"
              title="Select asset"
              onSelect={(value) => {
                console.log(value);
                addImageToMarkerTrigger(selectedTrigger.id, value).then(
                  (res) => {
                    fetchElements((elems) => {
                      console.log('asset link successful');
                    });
                  }
                );
              }}
            >
              {assets.data ? (
                <div>
                  {assets.data
                    .filter((assetItem) => assetItem.type === 'image')
                    .map((asset) => {
                      return (
                        <Dropdown.Item eventKey={asset.id} key={asset.id}>
                          {asset.id}
                        </Dropdown.Item>
                      );
                    })}
                </div>
              ) : null}
            </DropdownButton>
          </div>
        </div>
      ) : null}
      {selectedTrigger.image ? (
        <div className="row">
          <div className="col-md-12">
            <div
              key={selectedTrigger.image.id}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <Link to={`/dashboard/assets/${selectedTrigger.image.id}`}>
                <div style={{ width: '100%' }}>{selectedTrigger.image.id}</div>
              </Link>
              <div
                style={{
                  marginLeft: '24px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  props
                    .deleteMarkerTriggerImageDispatch(selectedTrigger.id)
                    .then((res) => {
                      fetchElements().then((elems) => {
                        console.log('delete successful');
                      });
                    });
                }}
              >
                <MdClose size={22} color="red" />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6">
          <Button
            variant="danger"
            style={styles.buttonContainer}
            block
            type="submit"
            disabled={false}
            onClick={() => {
              if (selectedTrigger.type === 'marker') {
                props
                  .deleteMarkerTriggerDispatch(selectedTrigger.id)
                  .then((res) => {
                    fetchTriggers().then((fetch) => {
                      console.log('delete success');
                      history.push('/dashboard/triggers');
                    });
                  });
              } else {
                props
                  .deleteAnchorTriggerDispatch(selectedTrigger.id)
                  .then((res) => {
                    fetchTriggers().then((fetch) => {
                      console.log('delete success');
                      history.push('/dashboard/triggers');
                    });
                  });
              }
            }}
          >
            Delete trigger
          </Button>
        </div>
      </div>
    </div>
  );
};

TriggerDetails.propTypes = {
  history: PropTypes.object,
  assets: PropTypes.object,
  selectedTrigger: PropTypes.object,
  setSelectedTrigger: PropTypes.func,
  fetchTriggers: PropTypes.func,
  fetchElements: PropTypes.func,
  updateMarkerTrigger: PropTypes.func,
  addImageToMarkerTrigger: PropTypes.func,
  updateAnchorTrigger: PropTypes.func,
  deleteMarkerTriggerDispatch: PropTypes.func,
  deleteAnchorTriggerDispatch: PropTypes.func,
  getAssetsDispatch: PropTypes.func,
  deleteMarkerTriggerImageDispatch: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  deleteMarkerTriggerDispatch,
  deleteAnchorTriggerDispatch,
  getAssetsDispatch,
  deleteMarkerTriggerImageDispatch,
};

export default connect(mapStateToProps, mapDispatchToProps)(TriggerDetails);
