import { persistReducer, persistStore } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../reducers';

const persistConfig = {
  key: 'primary',
  storage,
  whitelist: ['user', 'assets', 'elements', 'accounts', 'uploads', 'triggers'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnchancers = composeWithDevTools({});

export const store = createStore(
  persistedReducer,
  composeEnchancers(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
