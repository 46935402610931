import React from 'react';
import PropTypes from 'prop-types';
import { MdPlusOne } from 'react-icons/md';
import './MenuItem.css';
import { Link } from 'react-router-dom';

const getIcon = (name) => {
  switch (name) {
    case 'Triggers':
      return (
        <svg
          width="22"
          className="icon"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 10H17L14 19L8 1L5 10H1"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'Assets':
      return (
        <svg
          width="24"
          className="icon"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 2V9H20"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'Add':
      return <MdPlusOne size={26} />;
    case 'Add user':
      return <MdPlusOne size={26} />;
    case 'Add account':
      return <MdPlusOne size={26} />;
    case 'Profile':
      return (
        <svg
          width="24"
          className="icon"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="24"
          className="icon"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 15.9999V7.9999C20.9996 7.64918 20.9071 7.30471 20.7315 7.00106C20.556 6.69742 20.3037 6.44526 20 6.2699L13 2.2699C12.696 2.09437 12.3511 2.00195 12 2.00195C11.6489 2.00195 11.304 2.09437 11 2.2699L4 6.2699C3.69626 6.44526 3.44398 6.69742 3.26846 7.00106C3.09294 7.30471 3.00036 7.64918 3 7.9999V15.9999C3.00036 16.3506 3.09294 16.6951 3.26846 16.9987C3.44398 17.3024 3.69626 17.5545 4 17.7299L11 21.7299C11.304 21.9054 11.6489 21.9979 12 21.9979C12.3511 21.9979 12.696 21.9054 13 21.7299L20 17.7299C20.3037 17.5545 20.556 17.3024 20.7315 16.9987C20.9071 16.6951 20.9996 16.3506 21 15.9999Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.27002 6.95996L12 12.01L20.73 6.95996"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 22.08V12"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
};

const MenuItem = (props) => {
  const { name, redirect, selected } = props;
  return (
    <Link
      className={selected ? 'menu-link-selected' : 'menu-link'}
      to={name === 'Add' ? redirect : `/dashboard/${redirect}`}
      style={
        name === 'Add' || name === 'Add user' || name === 'Add account'
          ? styles.add
          : styles.container
      }
    >
      {getIcon(name)}
      <div className={selected ? 'label-selected' : 'label'}>
        <div>{name === 'Add' ? redirect.split('/')[2] : name}</div>
      </div>
    </Link>
  );
};

const styles = {
  container: {
    height: '44px',
    width: '183px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '18px',
    paddingRight: '18px',
    marginBottom: '12px',
    marginLeft: '8px',
    textDecoration: 'none',
  },
  add: {
    height: '44px',
    width: '183px',
    borderRadius: '8px',
    backgroundColor: 'rgba(229, 231, 236, 0.5)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '18px',
    paddingRight: '18px',
    marginLeft: '8px',
    textDecoration: 'none',
  },
};

MenuItem.propTypes = {
  name: PropTypes.string,
  redirect: PropTypes.string,
  selected: PropTypes.bool,
};

export default MenuItem;
