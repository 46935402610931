/* eslint-disable */
/* eslint-disable react-hooks/exhaustive-deps */
import '../Dashboard/components/columns/LeftColumn/LeftColumn.css';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  getUserSelfDispatch,
  logoutUserDispatch,
  postCreateUserDispatch,
} from '../../actions/user';
import {
  deleteElementAssetDispatch,
  deleteElementTriggerDispatch,
  getElementsDispatch,
  postAddElementDispatch,
  postElementLinkAssetDispatch,
  putElementLinkTriggerDispatch,
  putUpdateElementDispatch,
} from '../../actions/elements';
import {
  getTriggersDispatch,
  postAddMarkerTriggerDispatch,
  postUpdateMarkerTriggerDispatch,
  putAddImageToMarkerTriggerDispatch,
  putUpdateAnchorTriggerDispatch,
} from '../../actions/triggers';
import {
  getAssetsDispatch,
  postCreateAssetDispatch,
  putUpdateAssetFile,
} from '../../actions/assets';
import { isLoggedIn } from '../../util/http';
import { postCreateUploadDispatch } from '../../actions/uploads';
import MiddleColumn from './components/columns/MiddleColumn/MiddleColumn';
import RightColumn from './components/columns/RightColumn/RightColumn';
import LeftColumn from './components/columns/LeftColumn/LeftColumn';
import {
  getAccountsDispatch,
  postAddUserToAccount,
  postCreateAccountDispatch,
} from '../../actions/accounts';
import AssetDetailsView from './components/columns/RightColumn/DetailsView/AssetDetailsView';
import TriggerDetailsView from "./components/columns/RightColumn/DetailsView/TriggerDetailsView";
import ElementDetailsView from "./components/columns/RightColumn/DetailsView/ElementDetailsView";

const renderRightColumn = (slug, objectId) => {
  switch (slug) {
    case 'assets': {
      return <AssetDetailsView />;
    }
    case 'triggers': {
      return <TriggerDetailsView />;
    }
    case 'elements': {
      return <ElementDetailsView />;
    }
    default: {
      return <div>default</div>;
    }
  }
};

const Dashboard = (props) => {
  const location = useLocation();
  const path = location.pathname.endsWith('/')
    ? location.pathname
    : `${location.pathname}/`;
  const history = useHistory();
  const { slug, objectId } = useParams();

  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedAssetPlatform, setSelectedAssetPlatform] = useState('all');
  const [uploadingSpinner, setUploadingSpinner] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  useEffect(() => {
    if (!isLoggedIn()) {
      history.push('/login');
    }
  }, []);
  useEffect(() => {
    switch (slug) {
      case 'elements': {
        setSelectedElement(null);
        props.getElementsDispatch().then((elements) => {
          if (objectId) {
            const element = elements.payload.find(
              (elem) => elem.id === objectId
            );
            if (element) {
              setSelectedElement(element);
            } else {
              setSelectedElement({
                name: '',
                enabled: false,
              });
            }
          }
        });
        break;
      }
      case 'triggers': {
        setSelectedTrigger(null);
        props.getTriggersDispatch().then((triggers) => {
          if (objectId) {
            const trigger = triggers.payload.find(
              (trig) => trig.id === objectId
            );
            if (trigger) {
              setSelectedTrigger(trigger);
            } else {
              setSelectedTrigger({
                enabled: false,
                markerSizeCm: 0,
              });
            }
          }
        });
        break;
      }
      case 'assets': {
        setSelectedAsset(null);
        props.getAssetsDispatch().then((assets) => {
          if (objectId) {
            const asset = assets.payload.find((trig) => trig.id === objectId);
            if (asset) {
              setSelectedAsset(asset);
            }
          }
        });
        break;
      }
      case 'user': {
        props.getUserSelfDispatch().then((user) => {
          props.getAccountsDispatch().then((accounts) => {});
        });
        break;
      }
      default: {
        console.log('defaulted');
        break;
      }
    }
  }, [slug, objectId]);
  return (
    <div>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-2" style={{ height: '100vh'}}>
          <LeftColumn path={path} />
        </div>
        <div
          className={objectId ? 'col-md-7' : 'col-md-10'}
          style={{
            borderLeftColor: '#C2C6CF',
            borderLeftStyle: 'outset',
            borderLeftWidth: '1px',
            padding: 0
          }}
        >
          <MiddleColumn
            history={history}
            path={path}
            selectedElement={selectedElement}
            selectedTrigger={selectedTrigger}
            selectedAsset={selectedAsset}
            elements={props.elements}
            triggers={props.triggers}
            assets={props.assets}
            setSelectedElement={setSelectedElement}
            setSelectedTrigger={setSelectedTrigger}
            fetchTriggers={props.getTriggersDispatch}
            updateMarkerTrigger={props.postUpdateMarkerTriggerDispatch}
            updateAnchorTrigger={props.putUpdateAnchorTriggerDispatch}
            updateElement={props.putUpdateElementDispatch}
            deleteElementAsset={props.deleteElementAssetDispatch}
            deleteElementTrigger={props.deleteElementTriggerDispatch}
            linkElementAsset={props.postElementLinkAssetDispatch}
            linkElementTrigger={props.putElementLinkTriggerDispatch}
            addNewElement={props.postAddElementDispatch}
            addNewMarkerTrigger={props.postAddMarkerTriggerDispatch}
            addImageToMarkerTrigger={props.putAddImageToMarkerTriggerDispatch}
            selectedAssetPlatform={selectedAssetPlatform}
            setSelectedAssetPlatform={setSelectedAssetPlatform}
            postCreateUploadDispatch={props.postCreateUploadDispatch}
            uploads={props.uploads}
            postCreateAssetDispatch={props.postCreateAssetDispatch}
            uploadingSpinner={uploadingSpinner}
            setUploadingSpinner={setUploadingSpinner}
            putUpdateAssetFile={props.putUpdateAssetFile}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            addNewAccount={props.postCreateAccountDispatch}
            addNewUser={props.postCreateUserDispatch}
            linkUserToAccount={props.postAddUserToAccount}
            setSelectedAsset={setSelectedAsset}
          />
        </div>
        {objectId ? (
          <div
            className="col-md-3"
            style={{
              padding: 0,
              margin: 0,
              borderLeftColor: '#C2C6CF',
              borderLeftStyle: 'outset',
              borderLeftWidth: '1px',
            }}
          >
            {renderRightColumn(slug, objectId)}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  elements: state.elements,
  triggers: state.triggers,
  assets: state.assets,
  uploads: state.uploads,
});

const mapDispatchToProps = {
  getUserSelfDispatch,
  getElementsDispatch,
  getTriggersDispatch,
  getAssetsDispatch,
  logoutUserDispatch,
  postUpdateMarkerTriggerDispatch,
  putUpdateAnchorTriggerDispatch,
  putUpdateElementDispatch,
  deleteElementAssetDispatch,
  deleteElementTriggerDispatch,
  postElementLinkAssetDispatch,
  putElementLinkTriggerDispatch,
  postAddElementDispatch,
  postAddMarkerTriggerDispatch,
  putAddImageToMarkerTriggerDispatch,
  postCreateUploadDispatch,
  postCreateAssetDispatch,
  putUpdateAssetFile,
  getAccountsDispatch,
  postCreateAccountDispatch,
  postCreateUserDispatch,
  postAddUserToAccount,
};

Dashboard.propTypes = {
  location: PropTypes.object,
  match: PropTypes.any,
  getUserSelfDispatch: PropTypes.func,
  getElementsDispatch: PropTypes.func,
  getTriggersDispatch: PropTypes.func,
  getAssetsDispatch: PropTypes.func,
  logoutUserDispatch: PropTypes.func,
  elements: PropTypes.object,
  triggers: PropTypes.object,
  assets: PropTypes.object,
  postUpdateMarkerTriggerDispatch: PropTypes.func,
  putUpdateAnchorTriggerDispatch: PropTypes.func,
  putUpdateElementDispatch: PropTypes.func,
  deleteElementAssetDispatch: PropTypes.func,
  deleteElementTriggerDispatch: PropTypes.func,
  postElementLinkAssetDispatch: PropTypes.func,
  putElementLinkTriggerDispatch: PropTypes.func,
  postAddElementDispatch: PropTypes.func,
  postAddMarkerTriggerDispatch: PropTypes.func,
  putAddImageToMarkerTriggerDispatch: PropTypes.func,
  postCreateUploadDispatch: PropTypes.func,
  postCreateAssetDispatch: PropTypes.func,
  putUpdateAssetFile: PropTypes.func,
  uploads: PropTypes.object,
  getAccountsDispatch: PropTypes.func,
  postCreateAccountDispatch: PropTypes.func,
  postCreateUserDispatch: PropTypes.func,
  postAddUserToAccount: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
