/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Button, Dropdown } from 'react-bootstrap';
import { MdClose, MdMoreHoriz, MdOutlineEdit } from 'react-icons/md';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import Switch from 'react-switch';
import moment from 'moment';
import CustomDropdownToggle from '../../../common/CustomDropdownToggle';
import { EditText } from 'react-edit-text';
import {
  deleteElementAssetDispatch,
  deleteElementDispatch,
  deleteElementTriggerDispatch,
  getElementsDispatch,
  postElementLinkAssetDispatch,
  putUpdateElementDispatch,
} from '../../../../../../actions/elements';
import ClipLoader from 'react-spinners/ClipLoader';
import { getAssetsDispatch } from '../../../../../../actions/assets';
import Modal from 'react-bootstrap/Modal';

const ElementDetailsView = (props) => {
  const { slug, objectId } = useParams();
  const history = useHistory();
  // eslint-disable-next-line
  const [uploadingSpinner, setUploadingSpinner] = useState(false);
  // eslint-disable-next-line
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [assetSelectorOpen, setAssetSelectorOpen] = useState(false);

  const activeElement = props.elements.data.find((el) => el.id === objectId);
  const [elementEnabled, setElementEnabled] = useState(true);

  const [elementName, setElementName] = useState('');

  useEffect(() => {
    if (activeElement) {
      setElementEnabled(activeElement.enabled);
    }
  }, [activeElement]);

  useEffect(() => {
    if (activeElement.name) {
      setElementName(activeElement.name);
    } else {
      setElementName('');
    }
  }, [activeElement]);

  console.log(activeElement);

  return (
    <div className="asset-details-container">
      <div className="asset-details-top">
        <Dropdown onToggle={(isOpen) => {}}>
          <Dropdown.Toggle as={CustomDropdownToggle}>
            <MdMoreHoriz
              style={{ height: '24px', width: '24px', color: '#686A6F' }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              key="delete"
              eventKey="delete"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete scene
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <button
          className="asset-details-top-button"
          onClick={() => {
            history.push(`/dashboard/${slug}`);
          }}
        >
          <MdClose
            style={{ height: '24px', width: '24px', color: '#686A6F' }}
          />
        </button>
      </div>

      <div className="asset-details-prompt" style={{ marginTop: '24px' }}>
        Scene name
      </div>

      <div className="asset-details-name" style={{ marginLeft: '-4px' }}>
        <EditText
          id="editElementName"
          value={elementName}
          style={{ fontWeight: 600 }}
          onChange={(event) => setElementName(event)}
          placeholder={elementName === '' ? 'Insert name' : ''}
          onSave={(event) => {
            setUploadingSpinner(true);
            props
              .putUpdateElementDispatch(
                {
                  ...activeElement,
                  name: elementName,
                },
                activeElement.id
              )
              .then((res) => {
                props.getElementsDispatch().then((all) => {
                  setUploadingSpinner(false);
                });
              });
          }}
          onEditMode={(event) => {}}
        />
        <button
          className="asset-details-top-button"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => {
            document.getElementById('editElementName').click();
          }}
        >
          <MdOutlineEdit
            style={{ color: '#686A6F', width: '32px', height: '32px' }}
          />
        </button>
      </div>

      <div className="asset-details-meta">
        <div className="asset-details-meta-item">
          <div className="asset-details-prompt">ID</div>
          <div className="asset-details-meta-text">{activeElement.id}</div>
        </div>
      </div>

      <div className="asset-details-meta-item">
        <div className="asset-details-prompt">Modified</div>
        <div className="asset-details-meta-date">{`${moment(
          activeElement.updatedAt
        ).format('DD MMMM YYYY HH:mm')}`}</div>
      </div>

      <div className="asset-details-meta-item">
        <div className="asset-details-prompt">Assets</div>
        <div className="asset-details-meta-files">
          {activeElement.assets.map((assetRelation) => {
            return (
              <div
                key={assetRelation.relationId}
                className="element-details-meta-asset-item"
              >
                <a
                  href={`/dashboard/assets/${assetRelation.asset.id}`}
                  className="asset-details-meta-text"
                >
                  {assetRelation.asset.title
                    ? assetRelation.asset.title
                    : assetRelation.asset.id}
                </a>
                <button
                  className="asset-details-meta-files-item-delete-button"
                  style={{ marginTop: '-2px' }}
                  onClick={() => {
                    if (!props.elements.loading) {
                      props
                        .deleteElementAssetDispatch(
                          activeElement.id,
                          assetRelation.relationId
                        )
                        .then((res) => {
                          props.getElementsDispatch();
                        });
                    }
                  }}
                >
                  {props.elements.loading ? (
                    <ClipLoader size={6} color="#000000" />
                  ) : (
                    <MdClose color="#9C9EA4" width={6} height={6} />
                  )}
                </button>
              </div>
            );
          })}

          <div
            className="asset-details-meta-files-add"
            style={
              activeElement.assets && activeElement.assets.length !== 0
                ? { marginTop: '4px' }
                : { marginTop: 0 }
            }
          >
            {props.elements.loading ? (
              <ClipLoader size={24} color="#000000" />
            ) : (
              <Dropdown
                onToggle={(isOpen) => {
                  setAssetSelectorOpen(isOpen);
                  if (isOpen) {
                    props.getAssetsDispatch();
                  }
                }}
              >
                <Dropdown.Toggle as={CustomDropdownToggle}>
                  <button className="asset-details-meta-files-item-delete-button">
                    <span style={{ color: '#0072EF' }}>
                      {assetSelectorOpen ? (
                        <span className="element-details-asset-add ">
                          Link asset
                          <HiChevronUp
                            style={{ width: '16px', height: '16px' }}
                          />
                        </span>
                      ) : (
                        <span className="element-details-asset-add ">
                          Link asset
                          <HiChevronDown
                            style={{ width: '16px', height: '16px' }}
                          />
                        </span>
                      )}
                    </span>
                  </button>
                </Dropdown.Toggle>
                <Dropdown.Menu className="element-details-assets-dropdown">
                  {props.assets.data.map((asset) => {
                    return (
                      <Dropdown.Item
                        className="element-details-assets-dropdown-item"
                        key={asset.id}
                        eventKey={asset.id}
                        onClick={() => {
                          setUploadingSpinner(true);
                          props
                            .postElementLinkAssetDispatch(
                              {
                                role: 'string',
                                transform: {
                                  position: {
                                    x: 0,
                                    y: 0,
                                    z: 0,
                                  },
                                  rotation: {
                                    order: 'xyz',
                                    x: 0,
                                    y: 0,
                                    z: 0,
                                  },
                                  scale: {
                                    x: 1,
                                    y: 1,
                                    z: 1,
                                  },
                                },
                                assetId: asset.id,
                              },
                              activeElement.id
                            )
                            .then((res) => {
                              props.getElementsDispatch((res2) => {
                                setUploadingSpinner(false);
                              });
                            });
                        }}
                      >
                        {asset.title ? asset.title : `ID: ${asset.id}`}
                      </Dropdown.Item>
                    );
                  })}
                  {props.assets.loading ? (
                    <ClipLoader
                      size={24}
                      color="#000000"
                      style={{ padding: 0 }}
                    />
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
      <div className="asset-details-meta-item" style={{ alignItems: 'center' }}>
        <div className="asset-details-prompt">Trigger</div>
        <div className="asset-details-meta-files">
          {activeElement.trigger && activeElement.trigger.id ? (
            <div
              className="element-details-meta-asset-item"
              style={{ alignItems: 'center' }}
            >
              <a
                className="asset-details-meta-text"
                href={`/dashboard/triggers/${activeElement.trigger.id}`}
              >
                {activeElement.trigger.id}
              </a>
              <button
                className="asset-details-meta-files-item-delete-button"
                style={{ marginTop: '-2px' }}
                onClick={() => {
                  if (!props.elements.loading) {
                    props
                      .deleteElementTriggerDispatch(activeElement.id)
                      .then((res) => {
                        props.getElementsDispatch();
                      });
                  }
                }}
              >
                {props.elements.loading ? (
                  <ClipLoader size={6} color="#000000" />
                ) : (
                  <MdClose color="#9C9EA4" width={6} height={6} />
                )}
              </button>
            </div>
          ) : null}
        </div>
      </div>

      <div className="asset-details-meta">
        <div className="asset-details-meta-item">
          <div className="asset-details-prompt">Status</div>
          <div className="asset-details-meta-text">
            <Switch
              checked={elementEnabled}
              onChange={(checked) => {
                setElementEnabled(checked);
                props
                  .putUpdateElementDispatch(
                    {
                      ...activeElement,
                      enabled: checked,
                    },
                    activeElement.id
                  )
                  .then((res) => {
                    props.getElementsDispatch();
                  });
              }}
              checkedIcon={false}
              uncheckedIcon={false}
              width={48}
              height={22}
              onColor="#11A972"
            />
          </div>
        </div>
      </div>

      <Modal
        centered
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      >
        <Modal.Header style={{ justifyContent: 'flex-end' }}>
          <MdClose onClick={() => setShowDeleteModal(false)}></MdClose>
        </Modal.Header>
        <Modal.Title col-12>Delete scene?</Modal.Title>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <Button
                className="btn-delete"
                variant="danger"
                block
                type="submit"
                disabled={false}
                onClick={() => {
                  props.deleteElementDispatch(activeElement.id).then((res) => {
                    setShowDeleteModal(false);
                    history.push('/dashboard/elements');
                    props.getAssetsDispatch();
                  });
                }}
              >
                Delete scene
              </Button>
            </div>
            <div className="col-md-6">
              <Button
                className="btn-cancel"
                block
                variant="danger"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  elements: state.elements,
  assets: state.assets,
});

const mapDispatchToProps = {
  putUpdateElementDispatch,
  getElementsDispatch,
  getAssetsDispatch,
  postElementLinkAssetDispatch,
  deleteElementDispatch,
  deleteElementAssetDispatch,
  deleteElementTriggerDispatch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ElementDetailsView);
