/* eslint-disable react/prop-types */
import React from 'react';

// eslint-disable-next-line react/display-name
const CustomDropdownToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line
    <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export default CustomDropdownToggle;
