import { actionCreator } from './common';
import http, { getAccessToken } from '../util/http';
import {
  DELETE_ANCHOR_TRIGGER,
  DELETE_ANCHOR_TRIGGER_FAIL,
  DELETE_ANCHOR_TRIGGER_SUCCESS,
  DELETE_MARKER_TRIGGER,
  DELETE_MARKER_TRIGGER_FAIL,
  DELETE_MARKER_TRIGGER_IMAGE,
  DELETE_MARKER_TRIGGER_IMAGE_FAIL,
  DELETE_MARKER_TRIGGER_IMAGE_SUCCESS,
  DELETE_MARKER_TRIGGER_SUCCESS,
  GET_TRIGGERS,
  GET_TRIGGERS_FAIL,
  GET_TRIGGERS_SUCCESS,
  POST_ADD_MARKER_TRIGGER,
  POST_ADD_MARKER_TRIGGER_FAIL,
  POST_ADD_MARKER_TRIGGER_SUCCESS,
  POST_UPDATE_MARKER_TRIGGER,
  POST_UPDATE_MARKER_TRIGGER_FAIL,
  POST_UPDATE_MARKER_TRIGGER_SUCCESS,
  PUT_ADD_IMAGE_TO_MARKER_TRIGGER,
  PUT_ADD_IMAGE_TO_MARKER_TRIGGER_FAIL,
  PUT_ADD_IMAGE_TO_MARKER_TRIGGER_SUCCESS,
  PUT_UPDATE_ANCHOR_TRIGGER,
  PUT_UPDATE_ANCHOR_TRIGGER_FAIL,
  PUT_UPDATE_ANCHOR_TRIGGER_SUCCESS,
} from './types';

export const getTriggersDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(GET_TRIGGERS, data));
    return http
      .get('triggers', {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(actionCreator(GET_TRIGGERS_SUCCESS, response.data));
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(GET_TRIGGERS_FAIL, error.response));
        // eslint-disable-next-line
                alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const postUpdateMarkerTriggerDispatch = (data, triggerId) => {
  return async (dispatch) => {
    dispatch(actionCreator(POST_UPDATE_MARKER_TRIGGER, data));
    return http
      .post(`triggers/markers/${triggerId}`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(POST_UPDATE_MARKER_TRIGGER_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(
          actionCreator(POST_UPDATE_MARKER_TRIGGER_FAIL, error.response)
        );
        // eslint-disable-next-line
          alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const deleteMarkerTriggerDispatch = (triggerId) => {
  return async (dispatch) => {
    dispatch(actionCreator(DELETE_MARKER_TRIGGER));
    return http
      .delete(`triggers/markers/${triggerId}`, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(DELETE_MARKER_TRIGGER_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(DELETE_MARKER_TRIGGER_FAIL, error.response));
        // eslint-disable-next-line
          alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const putUpdateAnchorTriggerDispatch = (data, triggerId) => {
  return async (dispatch) => {
    dispatch(actionCreator(PUT_UPDATE_ANCHOR_TRIGGER, data));
    return http
      .put(`triggers/anchors/${triggerId}`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(PUT_UPDATE_ANCHOR_TRIGGER_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(PUT_UPDATE_ANCHOR_TRIGGER_FAIL, error.response));
        // eslint-disable-next-line
          alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const deleteAnchorTriggerDispatch = (triggerId) => {
  return async (dispatch) => {
    dispatch(actionCreator(DELETE_ANCHOR_TRIGGER));
    return http
      .delete(`triggers/anchors/${triggerId}`, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(DELETE_ANCHOR_TRIGGER_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(DELETE_ANCHOR_TRIGGER_FAIL, error.response));
        // eslint-disable-next-line
          alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const postAddMarkerTriggerDispatch = (data) => {
  return async (dispatch) => {
    dispatch(actionCreator(POST_ADD_MARKER_TRIGGER, data));
    return http
      .post(`triggers/markers`, data, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(POST_ADD_MARKER_TRIGGER_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(actionCreator(POST_ADD_MARKER_TRIGGER_FAIL, error.response));
        // eslint-disable-next-line
          alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const putAddImageToMarkerTriggerDispatch = (triggerId, assetId) => {
  return async (dispatch) => {
    dispatch(actionCreator(PUT_ADD_IMAGE_TO_MARKER_TRIGGER));
    return http
      .put(
        `triggers/markers/${triggerId}/image/${assetId}`,
        {},
        {
          headers: {
            Authorization: getAccessToken(),
          },
        }
      )
      .then(function (response) {
        return dispatch(
          actionCreator(PUT_ADD_IMAGE_TO_MARKER_TRIGGER_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(
          actionCreator(PUT_ADD_IMAGE_TO_MARKER_TRIGGER_FAIL, error.response)
        );
        // eslint-disable-next-line
          alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};

export const deleteMarkerTriggerImageDispatch = (triggerId) => {
  return async (dispatch) => {
    dispatch(actionCreator(DELETE_MARKER_TRIGGER_IMAGE));
    return http
      .delete(`triggers/markers/${triggerId}/images`, {
        headers: {
          Authorization: getAccessToken(),
        },
      })
      .then(function (response) {
        return dispatch(
          actionCreator(DELETE_MARKER_TRIGGER_IMAGE_SUCCESS, response.data)
        );
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(
          actionCreator(DELETE_MARKER_TRIGGER_IMAGE_FAIL, error.response)
        );
        // eslint-disable-next-line
          alert(error.response.data.code);
        throw new Error(error);
      })
      .finally(function () {
        // always executed
      });
  };
};
