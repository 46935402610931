import axios from 'axios';
import { store } from '../store/store';
import { API_BASE_URL, API_VERSION } from '../config/api';
import { createBrowserHistory } from 'history';
import { logoutUserDispatch } from '../actions/user';

export function getAccessToken() {
  const { accessToken } = store.getState().user.authentication;
  return `Bearer ${accessToken}`;
}

export function getRefreshToken() {
  const { refreshToken } = store.getState().user.authentication;
  return `${refreshToken}`;
}

export function isLoggedIn() {
  return store.getState().user.authentication.accessToken !== '';
}

export function getSelectedAccount() {
  let account = '';
  try {
    account = store.getState().user.loginData.memberships[0].accountId;
  } catch (e) {
    console.log('no account found');
  }
  return account;
}

// eslint-disable-next-line
function getUser() {
  const { user } = store.getState();
  return user;
}

const http = axios.create({
  baseURL: `${API_BASE_URL}/${API_VERSION}`,
  timeout: 180000,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // eslint-disable-next-line
    // config.headers.Authorization = getAccessToken();
    if (isLoggedIn()) {
      config.headers['ar-account-context'] = getSelectedAccount();
    }
    if (!isLoggedIn()) {
      delete config.headers.Authorization;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalRequest = error.config;

    if (
      (error.response.status === 401 || error.response.status === 404) &&
      !originalRequest._retry
    ) {
      if (error.message === 'timeout of 10000ms exceeded') {
        alert('Request timed out');
      }

      if (error.response.data.code === 'token-invalid') {
        store.dispatch(logoutUserDispatch()).then((res) => {
          createBrowserHistory().push('/login');
          window.location.reload();
        });
      }
    }

    return Promise.reject(error);
  }
);

export default http;
