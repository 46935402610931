import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import axios from 'axios';
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './styles';

function setFileType(originalFile, newType) {
  return new File([originalFile], originalFile.name, {
    type: newType,
    lastModified: originalFile.lastModified,
  });
}

const AddAsset = (props) => {
  const {
    history,
    assets,
    uploads,
    selectedAssetPlatform,
    setSelectedAssetPlatform,
    postCreateUploadDispatch,
    postCreateAssetDispatch,
    uploadingSpinner,
    setUploadingSpinner,
    replace,
    putUpdateAssetFile,
    replaceAsset,
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <div style={{ marginBottom: '48px' }}>
      <div className="row">
        <div className="col-md-12">
          {`Asset platform: `}
          <DropdownButton
            id="dropdown-basic-button"
            title={selectedAssetPlatform}
            onSelect={(value) => {
              console.log(value);
              setSelectedAssetPlatform(value);
            }}
          >
            <Dropdown.Item eventKey="all" key="all">
              all
            </Dropdown.Item>
            <Dropdown.Item eventKey="ios" key="ios">
              ios
            </Dropdown.Item>
            <Dropdown.Item eventKey="android" key="android">
              android
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="row" style={{ marginTop: '12px' }}>
        <div className="col-md-12" style={{ marginTop: '40px' }}>
          <Form method="post" encType="multipart/form-data">
            <Form.Group>
              <Form.File
                id="exampleFormControlFile2"
                label=""
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  setSelectedFile(event.target.files[0]);
                }}
              />
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="row" style={{ marginTop: '12px' }}>
        <div className="col-md-12">
          <Button
            style={styles.buttonContainer}
            block
            type="submit"
            disabled={!selectedFile}
            onClick={() => {
              setUploadingSpinner(true);
              let type = selectedFile.name.split('.').slice(-1)[0];
              if (selectedFile.type !== '') {
                type = selectedFile.type;
              }
              if (
                selectedFile.name.split('.').slice(-1)[0] === 'glb' ||
                selectedFile.name.split('.').slice(-1)[0] === 'GLB'
              ) {
                type = 'model/gltf-binary';
                const setTypeInFile = setFileType(
                  selectedFile,
                  'model/gltf-binary'
                );
                console.log(setTypeInFile);
                setSelectedFile(setTypeInFile);
              }
              if (
                selectedFile.name.split('.').slice(-1)[0] === 'gltf' ||
                selectedFile.name.split('.').slice(-1)[0] === 'GLTF'
              ) {
                type = 'model/gltf+json';
              }
              if (
                selectedFile.name.split('.').slice(-1)[0] === 'ab' ||
                selectedFile.name.split('.').slice(-1)[0] === 'AB'
              ) {
                type = 'application/octet-stream';
              }
              if (
                selectedFile.name.split('.').slice(-1)[0] === 'fbx' ||
                selectedFile.name.split('.').slice(-1)[0] === 'FBX'
              ) {
                type = 'model/fbx';
              }
              console.log(type);
              console.log(selectedFile);
              postCreateUploadDispatch({
                contentType: type,
                filename: selectedFile.name,
              })
                .then((res) => {
                  const file = selectedFile;
                  console.log(res);
                  console.log(res.payload);
                  const data = new FormData();
                  data.append('file', file);

                  axios
                    .put(res.payload.signedUrl, selectedFile, {
                      headers: {
                        'content-type': type,
                      },
                    })
                    .then(function (cloudRes) {
                      console.log(cloudRes);
                      if (replace) {
                        putUpdateAssetFile(
                          {
                            platform: selectedAssetPlatform,
                            uploadId: res.payload.id,
                          },
                          replaceAsset.id
                        )
                          .then((asset) => {
                            setUploadingSpinner(false);
                            history.push(
                              `/dashboard/assets/${asset.payload.id}`
                            );
                          })
                          .catch((assetErr) => {
                            setUploadingSpinner(false);
                            alert('upload failed');
                          });
                      } else {
                        postCreateAssetDispatch({
                          platform: selectedAssetPlatform,
                          uploadId: res.payload.id,
                        })
                          .then((asset) => {
                            setUploadingSpinner(false);
                            history.push(
                              `/dashboard/assets/${asset.payload.id}`
                            );
                          })
                          .catch((assetErr) => {
                            setUploadingSpinner(false);
                            alert('upload failed');
                          });
                      }
                    })
                    .catch(function (err) {
                      console.log(err);
                      console.log(JSON.stringify(err));
                      setUploadingSpinner(false);
                      alert('upload failed');
                    });
                })
                .catch((err) => {
                  alert('upload error');
                });
            }}
          >
            {uploads.loading || uploadingSpinner || assets.loading ? (
              <ClipLoader size={24} color="#ffffff" />
            ) : replace ? (
              'Add or replace for selected platform'
            ) : (
              'Add'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

AddAsset.propTypes = {
  history: PropTypes.object,
  assets: PropTypes.object,
  uploads: PropTypes.object,
  selectedAssetPlatform: PropTypes.string,
  setSelectedAssetPlatform: PropTypes.func,
  setSelectedFile: PropTypes.func,
  postCreateUploadDispatch: PropTypes.func,
  postCreateAssetDispatch: PropTypes.func,
  selectedFile: PropTypes.object,
  uploadingSpinner: PropTypes.bool,
  setUploadingSpinner: PropTypes.func,
  replace: PropTypes.bool,
  putUpdateAssetFile: PropTypes.func,
  replaceAsset: PropTypes.object,
};

export default AddAsset;
