import {
  POST_CREATE_UPLOAD,
  POST_CREATE_UPLOAD_FAIL,
  POST_CREATE_UPLOAD_SUCCESS,
} from '../actions/types';

const initialState = {
  loading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case POST_CREATE_UPLOAD:
      return {
        ...state,
        loading: true,
      };
    case POST_CREATE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case POST_CREATE_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    default: {
      return state;
    }
  }
}
