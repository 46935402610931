export const LOG_IN = 'LOG_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAIL = 'LOG_IN_FAIL';

export const LOG_OUT = 'LOG_OUT';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'REFRESH_TOKEN_FAIL';

export const GET_USER_SELF = 'GET_USER_SELF';
export const GET_USER_SELF_SUCCESS = 'GET_USER_SELF_SUCCESS';
export const GET_USER_SELF_FAIL = 'GET_USER_SELF_FAIL';

export const GET_ELEMENTS = 'GET_ELEMENTS';
export const GET_ELEMENTS_SUCCESS = 'GET_ELEMENTS_SUCCESS';
export const GET_ELEMENTS_FAIL = 'GET_ELEMENTS_FAIL';

export const GET_TRIGGERS = 'GET_TRIGGERS';
export const GET_TRIGGERS_SUCCESS = 'GET_TRIGGERS_SUCCESS';
export const GET_TRIGGERS_FAIL = 'GET_TRIGGERS_FAIL';

export const GET_ASSETS = 'GET_ASSETS';
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_FAIL = 'GET_ASSETS_FAIL';

export const POST_UPDATE_MARKER_TRIGGER = 'POST_UPDATE_MARKER_TRIGGER';
export const POST_UPDATE_MARKER_TRIGGER_SUCCESS =
  'POST_UPDATE_MARKER_TRIGGER_SUCCESS';
export const POST_UPDATE_MARKER_TRIGGER_FAIL =
  'POST_UPDATE_MARKER_TRIGGER_FAIL';

export const PUT_UPDATE_ANCHOR_TRIGGER = 'PUT_UPDATE_ANCHOR_TRIGGER';
export const PUT_UPDATE_ANCHOR_TRIGGER_SUCCESS =
  'PUT_UPDATE_ANCHOR_TRIGGER_SUCCESS';
export const PUT_UPDATE_ANCHOR_TRIGGER_FAIL = 'PUT_UPDATE_ANCHOR_TRIGGER_FAIL';

export const PUT_UPDATE_ELEMENT = 'PUT_UPDATE_ELEMENT';
export const PUT_UPDATE_ELEMENT_SUCCESS = 'PUT_UPDATE_ELEMENT_SUCCESS';
export const PUT_UPDATE_ELEMENT_FAIL = 'PUT_UPDATE_ELEMENT_FAIL';

export const DELETE_ELEMENT_ASSET = 'DELETE_ELEMENT_ASSET';
export const DELETE_ELEMENT_ASSET_SUCCESS = 'DELETE_ELEMENT_ASSET_SUCCESS';
export const DELETE_ELEMENT_ASSET_FAIL = 'DELETE_ELEMENT_ASSET_FAIL';

export const DELETE_ELEMENT_TRIGGER = 'DELETE_ELEMENT_TRIGGER';
export const DELETE_ELEMENT_TRIGGER_SUCCESS = 'DELETE_ELEMENT_TRIGGER_SUCCESS';
export const DELETE_ELEMENT_TRIGGER_FAIL = 'DELETE_ELEMENT_TRIGGER_FAIL';

export const POST_ELEMENT_LINK_ASSET = 'POST_ELEMENT_LINK_ASSET';
export const POST_ELEMENT_LINK_ASSET_SUCCESS =
  'POST_ELEMENT_LINK_ASSET_SUCCESS';
export const POST_ELEMENT_LINK_ASSET_FAIL = 'POST_ELEMENT_LINK_ASSET_FAIL';

export const PUT_ELEMENT_LINK_TRIGGER = 'PUT_ELEMENT_LINK_TRIGGER';
export const PUT_ELEMENT_LINK_TRIGGER_SUCCESS =
  'PUT_ELEMENT_LINK_TRIGGER_SUCCESS';
export const PUT_ELEMENT_LINK_TRIGGER_FAIL = 'PUT_ELEMENT_LINK_TRIGGER_FAIL';

export const POST_ADD_ELEMENT = 'POST_ADD_ELEMENT';
export const POST_ADD_ELEMENT_SUCCESS = 'POST_ADD_ELEMENT_SUCCESS';
export const POST_ADD_ELEMENT_FAIL = 'POST_ADD_ELEMENT_FAIL';

export const POST_ADD_MARKER_TRIGGER = 'POST_ADD_MARKER_TRIGGER';
export const POST_ADD_MARKER_TRIGGER_SUCCESS =
  'POST_ADD_MARKER_TRIGGER_SUCCESS';
export const POST_ADD_MARKER_TRIGGER_FAIL = 'POST_ADD_MARKER_TRIGGER_FAIL';

export const PUT_ADD_IMAGE_TO_MARKER_TRIGGER =
  'PUT_ADD_IMAGE_TO_MARKER_TRIGGER';
export const PUT_ADD_IMAGE_TO_MARKER_TRIGGER_SUCCESS =
  'PUT_ADD_IMAGE_TO_MARKER_TRIGGER_SUCCESS';
export const PUT_ADD_IMAGE_TO_MARKER_TRIGGER_FAIL =
  'PUT_ADD_IMAGE_TO_MARKER_TRIGGER';

export const POST_CREATE_UPLOAD = 'POST_CREATE_UPLOAD';
export const POST_CREATE_UPLOAD_SUCCESS = 'POST_CREATE_UPLOAD_SUCCESS';
export const POST_CREATE_UPLOAD_FAIL = 'POST_CREATE_UPLOAD_FAIL';

export const POST_CREATE_ASSET = 'POST_CREATE_ASSET';
export const POST_CREATE_ASSET_SUCCESS = 'POST_CREATE_ASSET_SUCCESS';
export const POST_CREATE_ASSET_FAIL = 'POST_CREATE_ASSET_FAIL';

export const POST_UPDATE_ASSET = 'POST_UPDATE_ASSET';
export const POST_UPDATE_ASSET_SUCCESS = 'POST_UPDATE_ASSET_SUCCESS';
export const POST_UPDATE_ASSET_FAIL = 'POST_UPDATE_ASSET_FAIL';

export const DELETE_MARKER_TRIGGER = 'DELETE_MARKER_TRIGGER';
export const DELETE_MARKER_TRIGGER_SUCCESS = 'DELETE_MARKER_TRIGGER_SUCCESS';
export const DELETE_MARKER_TRIGGER_FAIL = 'DELETE_MARKER_TRIGGER_FAIL';

export const DELETE_ANCHOR_TRIGGER = 'DELETE_ANCHOR_TRIGGER';
export const DELETE_ANCHOR_TRIGGER_SUCCESS = 'DELETE_ANCHOR_TRIGGER_SUCCESS';
export const DELETE_ANCHOR_TRIGGER_FAIL = 'DELETE_ANCHOR_TRIGGER';

export const DELETE_MARKER_TRIGGER_IMAGE = 'DELETE_MARKER_TRIGGER_IMAGE';
export const DELETE_MARKER_TRIGGER_IMAGE_SUCCESS =
  'DELETE_MARKER_TRIGGER_IMAGE_SUCCESS';
export const DELETE_MARKER_TRIGGER_IMAGE_FAIL =
  'DELETE_MARKER_TRIGGER_IMAGE_FAIL';

export const DELETE_ASSET = 'DELETE_ASSET';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_FAIL = 'DELETE_ASSET_FAIL';

export const DELETE_ELEMENT = 'DELETE_ELEMENT';
export const DELETE_ELEMENT_SUCCESS = 'DELETE_ELEMENT_SUCCESS';
export const DELETE_ELEMENT_FAIL = 'DELETE_ELEMENT_FAIL';

export const PUT_UPDATE_ASSET_FILE = 'PUT_UPDATE_ASSET_FILE';
export const PUT_UPDATE_ASSET_FILE_SUCCESS = 'PUT_UPDATE_ASSET_FILE_SUCCESS';
export const PUT_UPDATE_ASSET_FILE_FAIL = 'PUT_UPDATE_ASSET_FILE';

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAIL = 'GET_ACCOUNTS_FAIL';

export const POST_CREATE_USER = 'POST_CREATE_USER';
export const POST_CREATE_USER_SUCCESS = 'POST_CREATE_USER_SUCCESS';
export const POST_CREATE_USER_FAIL = 'POST_CREATE_USER_FAIL';

export const POST_CREATE_ACCOUNT = 'POST_CREATE_ACCOUNT';
export const POST_CREATE_ACCOUNT_SUCCESS = 'POST_CREATE_ACCOUNT_SUCCESS';
export const POST_CREATE_ACCOUNT_FAIL = 'POST_CREATE_ACCOUNT_FAIL';

export const POST_ADD_USER_TO_ACCOUNT = 'POST_ADD_USER_TO_ACCOUNT';
export const POST_ADD_USER_TO_ACCOUNT_SUCCESS =
  'POST_ADD_USER_TO_ACCOUNT_SUCCESS';
export const POST_ADD_USER_TO_ACCOUNT_FAIL = 'POST_ADD_USER_TO_ACCOUNT_FAIL';

export const POST_ADD_ASSET_MEDIA_FILE = 'POST_ADD_ASSET_MEDIA_FILE';
export const POST_ADD_ASSET_MEDIA_FILE_SUCCESS =
  'POST_ADD_ASSET_MEDIA_FILE_SUCCESS';
export const POST_ADD_ASSET_MEDIA_FILE_FAIL = 'POST_ADD_ASSET_MEDIA_FILE_FAIL';

export const PUT_UPDATE_ASSET_PLATFORM = 'PUT_UPDATE_ASSET_PLATFORM';
export const PUT_UPDATE_ASSET_PLATFORM_SUCCESS =
  'PUT_UPDATE_ASSET_PLATFORM_SUCCESS';
export const PUT_UPDATE_ASSET_PLATFORM_FAIL = 'PUT_UPDATE_ASSET_PLATFORM_FAIL';

export const DELETE_ASSET_FILE = 'DELETE_ASSET_FILE';
export const DELETE_ASSET_FILE_SUCCESS = 'DELETE_ASSET_FILE_SUCCESS';
export const DELETE_ASSET_FILE_FAIL = 'DELETE_ASSET_FILE_FAIL';
