import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './ElementItem.css';
import moment from 'moment';

const ElementItem = (props) => {
  const { element, selected, onClick } = props;
  if (element.enabled === false) {
    return (
      <Link
        to={`/dashboard/elements/${element.id}`}
        style={selected ? styles.highlight : styles.container}
        onClick={() => {
          onClick();
          // history.push(`/dashboard/elements/${element.id}`);
        }}
      >
        <div
          style={
            window.location.href.includes(element.id)
              ? { backgroundColor: 'rgba(26, 141, 255, 0.06)' }
              : {}
          }
        >
          <ul className="list-group list-group-horizontal">
            <li className="list-group-item flex-fill col-md-4">{`${element.name}`}</li>
            <li className="list-group-item flex-fill col-md-4">
              <a href="/" className="state-disabled">{`${element.enabled}`}</a>
            </li>
            <li className="last-modified-column break list-group-item flex-fill col-md-4">{`${
              element.updatedAt
                ? moment(element.updatedAt).format('DD MMM YYYY HH:mm')
                : ''
            }`}</li>
          </ul>
        </div>
      </Link>
    );
  } else if (element.enabled === true)
    return (
      <Link
        to={`/dashboard/elements/${element.id}`}
        style={selected ? styles.highlight : styles.container}
        onClick={() => {
          onClick();
          // history.push(`/dashboard/elements/${element.id}`);
        }}
      >
        <div
          style={
            window.location.href.includes(element.id)
              ? { backgroundColor: 'rgba(26, 141, 255, 0.06)' }
              : {}
          }
        >
          <ul className="list-group list-group-horizontal">
            <li className="list-group-item flex-fill col-md-4">{`${element.name}`}</li>
            <li className="list-group-item flex-fill col-md-4">
              <a href="/" className="state-enabled">{`${
                element.enabled ? 'Enabled' : 'Disabled'
              }`}</a>
            </li>
            <li className="break list-group-item flex-fill col-md-4">
              <div className="last-modified-column">{`${
                element.updatedAt
                  ? moment(element.updatedAt).format('DD MMM YYYY HH:mm')
                  : ''
              }`}</div>
            </li>
          </ul>
        </div>
      </Link>
    );
};

const styles = {
  container: {
    minHeight: '72px',
    width: '100%',
    borderRadius: '16px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '18px',
    paddingRight: '18px',
    marginBottom: '12px',
    textDecoration: 'none',
  },
  highlight: {
    minHeight: '72px',
    width: '100%',
    borderRadius: '16px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '18px',
    paddingRight: '18px',
    marginBottom: '12px',
    textDecoration: 'none',
  },
  label: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '18px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 400,
  },
};

ElementItem.propTypes = {
  name: PropTypes.string,
  redirect: PropTypes.string,
  element: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ElementItem;
