import {
  GET_TRIGGERS,
  GET_TRIGGERS_FAIL,
  GET_TRIGGERS_SUCCESS,
} from '../actions/types';

const initialState = {
  loading: false,
  data: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRIGGERS:
      return {
        ...state,
        loading: true,
      };
    case GET_TRIGGERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_TRIGGERS_FAIL:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default: {
      return state;
    }
  }
}
