import {
  GET_ELEMENTS,
  GET_ELEMENTS_FAIL,
  GET_ELEMENTS_SUCCESS,
} from '../actions/types';

const initialState = {
  loading: false,
  data: [],
  error: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ELEMENTS:
      return {
        ...state,
        loading: true,
        error: {},
      };
    case GET_ELEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_ELEMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
}
